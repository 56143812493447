import React from 'react';

import { ConsentType } from 'models/domain/xs2a';
import Attribute from 'components/reusable/authorization/Attribute/Attribute.layout';

import { ConsentDetailsProps } from './ConsentDetails.types';
import AccountItem from './AccountItem/AccountItem.layout';
import Styled from './ConsentDetails.styled';


export default function ConsentDetailsLayout(props: ConsentDetailsProps) {
    const { t, tppName, consentDetails } = props;

    const consentTypeTranslationKey = consentDetails.consentType === ConsentType.RECURRING ? 'valueRecurring' : 'valueOneOff';

    return (
        <main>
            <Attribute value={tppName} label={t('attributes.tppName.label')} tooltip={t('attributes.tppName.tooltip')} />

            <Styled.AccountListWrap>
                { consentDetails.accounts.map((account) => <AccountItem key={account.iban} t={t} {...account} />) }
            </Styled.AccountListWrap>

            <Styled.AttributesWrap>
                <Attribute
                    value={t(`attributes.consentType.${consentTypeTranslationKey}`)}
                    label={t('attributes.consentType.label')}
                    tooltip={t('attributes.consentType.tooltip')}
                />
                <Attribute value={consentDetails.validUntil} label={t('attributes.validUntil.label')} tooltip={t('attributes.validUntil.tooltip')} />
                <Attribute value={consentDetails.frequencyPerDay} label={t('attributes.frequencyPerDay.label')} tooltip={t('attributes.frequencyPerDay.tooltip')} />
            </Styled.AttributesWrap>
        </main>
    );
}
