import React, { FunctionComponent } from 'react';
import { NavigationBarOuterWrap } from 'assets/styles/global/layout.styled';
import Styled from './NavigationBarDecoration.styled';

const NavigationBarDecoration: FunctionComponent = () => (
    <Styled.NavigationBarDecoration>
        <NavigationBarOuterWrap>
            <Styled.NavigationBarDecorationInnerWrap>
                <Styled.NavigationBarDecorationLighter />
                <Styled.NavigationBarDecorationLightest />
            </Styled.NavigationBarDecorationInnerWrap>
        </NavigationBarOuterWrap>
    </Styled.NavigationBarDecoration>
);

export default NavigationBarDecoration;
