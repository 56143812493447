import React from 'react';
import { connect } from 'react-redux';

import { State } from 'models/app/store';

import withLoading from './withLoading.layout';
import { WithInjectedProps, WithLoadingProps } from './withLoading.types';

function createWithLoading<T>(WrappedComponent: React.ComponentType, loadingData: WithLoadingProps) {
    const mapStateToProps = (state: State) => {
        return { isLoading: loadingData.loadingSelector(state) ?? false };
    };

    return connect<WithInjectedProps, {}, T>(mapStateToProps)(withLoading(WrappedComponent, loadingData));
}

export default createWithLoading;
