import React from 'react';
import Styled from 'assets/styles/global/layout.styled';
import appLogo from 'assets/img/logo.svg';
import AppVersionLayout from './AppVersion/AppVersion.layout';

function Footer() {
    return (
        <Styled.FooterOuterWrap>
            <Styled.FooterInnerWrap>
                <AppVersionLayout />
                <img src={appLogo} alt="OKEO" width={40} height={12} style={{ filter: 'grayscale(1) opacity(.5)' }} />
            </Styled.FooterInnerWrap>
        </Styled.FooterOuterWrap>
    );
}

export default Footer;
