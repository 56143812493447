import styled from 'styled-components';
import { WithTheme } from 'config/theme';
import { ColorTransition } from 'assets/styles/animations/animations.styled';

const LinksWrap = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Link = styled.a`
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  text-decoration: none;
  color: ${({ theme }: WithTheme) => theme.colors.gray.xdark};
  ${ColorTransition};
  
  &:hover,
  &:active {
    color: ${({ theme }: WithTheme) => theme.colors.gray.dark};
  }
`;

const Dot = styled.div`
    display: block;
    width: 7px;
    height: 7px;
    padding: 0;
    background-color: ${({ theme }: WithTheme) => theme.colors.gray.xdark};
    border-radius: 100%;
    background-clip: padding-box;
`;

export default { LinksWrap, Link, Dot };
