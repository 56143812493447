import { UserState } from 'models/app/store';
import {
    SIGN_OFF,
    SIGN_ON,
    SIGN_ON_FAILURE,
    SIGN_ON_SUCCESS,
    UserActions
} from 'store/actions/user.types';
import { User } from 'models/domain/user';
import { createReducer, Handlers } from './reducer';
import { CLEAR_STORE } from '../actions/common.types';

const initialUserData = {
    userPreferences: [],
    shortName: null
};

export const initialState: UserState = {
    isAuthenticated: false,
    isLoading: false,
    data: initialUserData
};


const handlers: Handlers<UserState, UserActions> = {
    [SIGN_ON]: () => {
        return { isLoading: true };
    },
    [SIGN_ON_FAILURE]: () => {
        return { isAuthenticated: false, isLoading: false };
    },
    [SIGN_ON_SUCCESS]: (payload: User) => {
        return {
            isAuthenticated: true,
            isLoading: false,
            data: payload
        };
    },
    [SIGN_OFF]: () => {
        return initialState;
    },
    [CLEAR_STORE]: () => {
        return initialState;
    }
};

export default createReducer(initialState, handlers);
