import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import State from 'models/app/store';

import userReducer from './userReducer';
import appReducer from './appReducer';
import loginReducer from './loginReducer';
import smsCodeFormReducer from './smsCodeFormReducer';
import xs2aReducer from './xs2aReducer';
import authorisationReducer from './authorisationReducer';
import workflowReducer from './workflowReducer';

export const rootReducer = combineReducers<State>({
    form: formReducer,
    user: userReducer,
    app: appReducer,
    login: loginReducer,
    sms: smsCodeFormReducer,
    xs2a: xs2aReducer,
    authorisation: authorisationReducer,
    workflow: workflowReducer,
});
