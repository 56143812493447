import React, { FormEvent } from 'react';
import { Field } from 'redux-form';

import { FormField } from 'components/reusable/FormField/FormField.layout';
import TextButton from 'components/reusable/Buttons/TextButton.styled';
import Icons from 'assets/styles/icons/icons';
import { noop } from 'utils/function-tools';

import {
    PageSubHeader,
    InfoIcon,
    SecondaryActionWrap
} from 'components/pages/Login/LoginStep/LoginStep.styled';
import LoginStep from 'components/pages/Login/LoginStep/LoginStep';

import Styled from './LoginAuthorisatin.styled';
import { LoginProps } from './LoginAuthorisation.types';

export const LoginAuthorisation = (props: LoginProps) => {
    const {
        t,
        dispatchSignIn,
        fieldsValues,
        formErrors,
    } = props;


    const isValid = (): boolean => {
        return !!fieldsValues.login && !!fieldsValues.password && !Object.keys(formErrors).length;
    };

    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();
        isValid() ? dispatchSignIn(fieldsValues.login, fieldsValues.password) : {};
    };

    return (
        <LoginStep pageHeader={t('pageHeader.loginToApp')}>
            <PageSubHeader>{t('loginForm.header')}</PageSubHeader>

            <InfoIcon
                icon={Icons.infoCircle}
                color="accent.light"
                data-tip={t('loginForm.infoIcon.tooltip')}
            />

            <Styled.Form
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <Field
                    name="login"
                    label={t('loginForm.loginField.label')}
                    iconName="person"
                    component={FormField}
                    type="text"
                    id="login"
                    t={t}
                />

                <Field
                    name="password"
                    type="password"
                    label={t('loginForm.passwordField.label')}
                    id="password"
                    iconName="key"
                    component={FormField}
                    t={t}
                />

                <Styled.CenteredButton
                    width="auto"
                    type="submit"
                    disabled={!isValid()}
                >
                    {t('buttons.logIn.text')}
                </Styled.CenteredButton>
            </Styled.Form>

            <SecondaryActionWrap>
                {t('loginForm.forgotPasswordText')}
                <TextButton onClick={() => noop}>{t('buttons.restoreAccess.text')}</TextButton>
            </SecondaryActionWrap>

        </LoginStep>
    );
};
