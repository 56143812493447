import { ofType } from 'redux-observable';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { ApiError } from 'models/meta/api';
import { Epic } from 'models/meta/epic';
import { WorkflowStep } from 'models/app/workflow';
import { AuthorisationStatus, StartAuthorisationResponse, VerificationResult } from 'models/domain/authorisation';

import { setWorkflowStep } from 'store/actions/workflow';
import {
    cancelAuthorisationFailure,
    cancelAuthorisationSuccess,
    resendCodeFailure,
    resendCodeSuccess,
    startAuthorisationFailure,
    startAuthorisationSuccess,
    verifyCodeFailure,
    verifyCodeSuccess
} from 'store/actions/authorisation';

import { actionSuccessToast } from 'store/actions/common';

import {
    CANCEL_AUTHORISATION,
    CANCEL_AUTHORISATION_SUCCESS,
    RESEND_CODE,
    RESEND_CODE_SUCCESS,
    START_AUTHORISATION,
    VERIFY_CODE,
    VERIFY_CODE_SUCCESS
} from 'store/actions/authorisation.types';

import { setSmsCodeFormError } from 'store/actions/smsCodeForm';


export const onStartAuthorisation: Epic = (action$, state$, { authorisation: AuthorisationService }) =>
    action$.pipe(
        ofType(START_AUTHORISATION),
        switchMap(() => {
            const { xs2aAuthorisationId, authorisationType } = state$.value.workflow;

            return from(AuthorisationService.startAuthorisation(authorisationType, xs2aAuthorisationId))
                .pipe(
                    concatMap((payload: StartAuthorisationResponse) => of(
                        startAuthorisationSuccess(payload),
                        setWorkflowStep(WorkflowStep.VERIFICATION)
                    )),
                    catchError((error: ApiError) => {
                        return of(...[startAuthorisationFailure(), ...error.actions]);
                    })
                );
        })
    );

export const onCancelAuthorisation: Epic = (action$, state$, { authorisation: AuthorisationService }) =>
    action$.pipe(
        ofType(CANCEL_AUTHORISATION),
        switchMap(() => {
            const { authorisationType } = state$.value.workflow;
            const { authorisationId } = state$.value.authorisation;

            return from(AuthorisationService.cancelAuthorisation(authorisationType, authorisationId)).pipe(
                map(() => cancelAuthorisationSuccess()),
                catchError(() => of(cancelAuthorisationFailure()))
            );
        })
    );

export const onCancelAuthorisationSuccess: Epic = (action$, state$) =>
    action$.pipe(
        ofType(CANCEL_AUTHORISATION_SUCCESS),
        switchMap(() => of(setWorkflowStep(WorkflowStep.DECLINED)))
    );


export const onResendSmsCode: Epic = (action$, state$, { authorisation: AuthorisationService }) =>
    action$.pipe(
        ofType(RESEND_CODE),
        switchMap(() => {
            const { authorisationType } = state$.value.workflow;
            const { authorisationId } = state$.value.authorisation;

            return from(AuthorisationService.resendCode(authorisationType, authorisationId)).pipe(
                map(resendCodeSuccess),
                catchError(() => of(resendCodeFailure()))
            );
        })
    );

export const onResendSmsCodeSuccess: Epic = (action$, state$) =>
    action$.pipe(
        ofType(RESEND_CODE_SUCCESS),
        switchMap(({ payload: authorisationStatus }) => {
            if (authorisationStatus === AuthorisationStatus.TOO_MANY_SMSES) {
                return of(setWorkflowStep(WorkflowStep.DECLINED));
            } else {
                return of(actionSuccessToast('common', 'smsCodeSent'));
            }
        })
    );


export const onVerifyCode: Epic = (action$, state$, { authorisation: AuthorisationService }) =>
    action$.pipe(
        ofType(VERIFY_CODE),
        switchMap(({ payload: code }) => {
            const { authorisationType } = state$.value.workflow;
            const { authorisationId } = state$.value.authorisation;

            return from(AuthorisationService.verifyCode(authorisationType, authorisationId, code)).pipe(
                map((verifyCodeResponse) => verifyCodeSuccess(verifyCodeResponse)),
                catchError(() => of(verifyCodeFailure()))
            );
        })
    );

export const onVerifyCodeSuccess: Epic = action$ =>
    action$.pipe(
        ofType(VERIFY_CODE_SUCCESS),
        switchMap(({ payload: { verificationResult, authorisationStatus } }) => {
            if (verificationResult === VerificationResult.PASSED && authorisationStatus === AuthorisationStatus.FINALISED) {
                return of(setWorkflowStep(WorkflowStep.FINALISED));

            } else if (authorisationStatus !== AuthorisationStatus.FINALISED && authorisationStatus !== AuthorisationStatus.STARTED) {
                return of(setWorkflowStep(WorkflowStep.DECLINED));

            } else {
                return of(setSmsCodeFormError(true));
            }
        })
    );
