type SET_SMS_FIELD_VALUE = 'SET_SMS_FIELD_VALUE';
type CLEAR_SMS_CODE_FORM = 'CLEAR_SMS_CODE_FORM';
type SET_SMS_CODE_FORM_ERROR = 'SET_SMS_CODE_FORM_ERROR';

export const SET_SMS_FIELD_VALUE: SET_SMS_FIELD_VALUE = 'SET_SMS_FIELD_VALUE';
export const CLEAR_SMS_CODE_FORM: CLEAR_SMS_CODE_FORM = 'CLEAR_SMS_CODE_FORM';
export const SET_SMS_CODE_FORM_ERROR: SET_SMS_CODE_FORM_ERROR = 'SET_SMS_CODE_FORM_ERROR';

export interface SetSmsFieldValuePayload {
    fieldName: string,
    value: string,
}

export interface SetSmsFieldValue {
    type: SET_SMS_FIELD_VALUE;
    payload: SetSmsFieldValuePayload;
}

export interface ClearSmsCodeForm {
    type: CLEAR_SMS_CODE_FORM;
}

export interface SetSmsCodeFormError {
    type: SET_SMS_CODE_FORM_ERROR;
    payload: boolean;
}

export type SmsCodeFormActions = SetSmsFieldValue | ClearSmsCodeForm | SetSmsCodeFormError;
