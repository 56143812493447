import React from 'react';

import { LoginStep } from 'models/app/workflow';

import blockedUser from 'assets/img/blocked-user.svg';
import { PageSubHeader, DecorationImage, SecondaryActionWrap } from 'components/pages/Login/LoginStep/LoginStep.styled';
import LoginStepLayout from 'components/pages/Login/LoginStep/LoginStep';
import TextButton from 'components/reusable/Buttons/TextButton.styled';

import { LoginTemporaryBlockadeProps } from './LoginTemporaryBlockade.types';

export const LoginTemporaryBlockade = ({ t, dispatchSetLoginStep }: LoginTemporaryBlockadeProps) => {

    return (
        <LoginStepLayout pageHeader={t('pageHeader.temporaryBlockage')}>
            <PageSubHeader>{t('temporaryBlockageWindow.header')}</PageSubHeader>

            <DecorationImage src={blockedUser} alt="" width={136} height={160} />

            <SecondaryActionWrap>
                {t('temporaryBlockageWindow.text')}
                <TextButton onClick={() => dispatchSetLoginStep(LoginStep.AUTHORISATION)}>{t('buttons.return.text')}</TextButton>
            </SecondaryActionWrap>

        </LoginStepLayout>
    );
};
