import React from 'react';

import Heading from 'components/reusable/authorization/Heading/Heading.layout';
import { Button } from 'components/reusable/Buttons/Button';
import ButtonsWrap from 'components/reusable/Buttons/ButtonsWrap.styled';

import { ConsentFinalisedProps } from './ConsentFinalised.types';


export default function ConsentFinalised(props: ConsentFinalisedProps) {
    const { t } = props;

    return (
        <main>
            <Heading
                pageHeader={t('consentGranted.header')}
                iconName="checkMarkCircle"
                iconColor="accent.light"
                pageSubheader={t('consentGranted.subHeader')}
            />

            <ButtonsWrap>
                <Button onClick={() => props.dispatchFinishWorkflow()}>{t('common:buttons.redirectBack.text')}</Button>
            </ButtonsWrap>
        </main>
    );
}
