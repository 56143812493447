import styled from 'styled-components';
import { WithTheme } from 'config/theme';

const TextButton = styled.button`
    display: inline-block;
    padding: 0;
    margin: 0 0 0 1ch;
    min-width: unset;
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-weight: 700;
    color: ${({ theme }: WithTheme) => theme.colors.accent.light};  
    
    &:hover,
    &:active,
    &:focus {
        color: ${({ theme }: WithTheme) => theme.colors.accent.dark};  
    }
`;

export default TextButton;
