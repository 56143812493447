import { Action } from 'models/meta/action';
import {
    AuthorisationStatus,
    SmsCode,
    StartAuthorisationResponse,
    VerifyCodeResponse
} from 'models/domain/authorisation';


type START_AUTHORISATION = 'START_AUTHORISATION';
type START_AUTHORISATION_SUCCESS = 'START_AUTHORISATION_SUCCESS';
type START_AUTHORISATION_FAILURE = 'START_AUTHORISATION_FAILURE';

type CANCEL_AUTHORISATION = 'CANCEL_AUTHORISATION';
type CANCEL_AUTHORISATION_SUCCESS = 'CANCEL_AUTHORISATION_SUCCESS';
type CANCEL_AUTHORISATION_FAILURE = 'CANCEL_AUTHORISATION_FAILURE';

type VERIFY_CODE = 'VERIFY_CODE';
type VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
type VERIFY_CODE_FAILURE = 'VERIFY_CODE_FAILURE';

type RESEND_CODE = 'RESEND_CODE';
type RESEND_CODE_SUCCESS = 'RESEND_CODE_SUCCESS';
type RESEND_CODE_FAILURE = 'RESEND_CODE_FAILURE';

export const START_AUTHORISATION = 'START_AUTHORISATION';
export const START_AUTHORISATION_SUCCESS = 'START_AUTHORISATION_SUCCESS';
export const START_AUTHORISATION_FAILURE = 'START_AUTHORISATION_FAILURE';

export const CANCEL_AUTHORISATION = 'CANCEL_AUTHORISATION';
export const CANCEL_AUTHORISATION_SUCCESS = 'CANCEL_AUTHORISATION_SUCCESS';
export const CANCEL_AUTHORISATION_FAILURE = 'CANCEL_AUTHORISATION_FAILURE';

export const VERIFY_CODE = 'VERIFY_CODE';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_FAILURE = 'VERIFY_CODE_FAILURE';

export const RESEND_CODE = 'RESEND_CODE';
export const RESEND_CODE_SUCCESS = 'RESEND_CODE_SUCCESS';
export const RESEND_CODE_FAILURE = 'RESEND_CODE_FAILURE';


export interface StartAuthorisation extends Action {
    type: START_AUTHORISATION;
}

export interface StartAuthorisationSuccess extends Action {
    type: START_AUTHORISATION_SUCCESS;
    payload: StartAuthorisationResponse;
}

export interface StartAuthorisationFailure extends Action {
    type: START_AUTHORISATION_FAILURE;
}

export interface CancelAuthorisation extends Action {
    type: CANCEL_AUTHORISATION;
}

export interface CancelAuthorisationSuccess extends Action {
    type: CANCEL_AUTHORISATION_SUCCESS;
}

export interface CancelAuthorisationFailure extends Action {
    type: CANCEL_AUTHORISATION_FAILURE;
}

export interface VerifyCode extends Action {
    type: VERIFY_CODE;
    payload: SmsCode;
}

export interface VerifyCodeSuccess extends Action {
    type: VERIFY_CODE_SUCCESS;
    payload: VerifyCodeResponse;
}

export interface VerifyCodeFailure extends Action {
    type: VERIFY_CODE_FAILURE;
}

export interface ResendCode extends Action {
    type: RESEND_CODE;
}

export interface ResendCodeSuccess extends Action {
    type: RESEND_CODE_SUCCESS;
    payload: AuthorisationStatus;
}

export interface ResendCodeFailure extends Action {
    type: RESEND_CODE_FAILURE;
}


type StartAuthorisationActions = StartAuthorisation | StartAuthorisationSuccess | StartAuthorisationFailure;
type CancelAuthorisationActions = CancelAuthorisation | CancelAuthorisationSuccess | CancelAuthorisationFailure;
type VerifyCodeActions = VerifyCode | VerifyCodeSuccess | VerifyCodeFailure;
type ResendCodeActions = ResendCode | ResendCodeSuccess | ResendCodeFailure;

export type AuthorisationActions = StartAuthorisationActions | CancelAuthorisationActions | VerifyCodeActions | ResendCodeActions;
