import { Action } from 'models/meta/action';


type START_APP_LOADING = 'START_APP_LOADING';
type END_APP_LOADING = 'END_APP_LOADING';

export const START_APP_LOADING: START_APP_LOADING = 'START_APP_LOADING';
export const END_APP_LOADING: END_APP_LOADING = 'END_APP_LOADING';


export interface StartAppLoading extends Action {
    type: START_APP_LOADING;
}

export interface EndAppLoading extends Action {
    type: END_APP_LOADING;
}


type LoadingActions = StartAppLoading | EndAppLoading;

export type AppActions = LoadingActions;
