import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMapTo } from 'rxjs/operators';

import { Epic } from 'models/meta/epic';
import { endAppLoading, startAppLoading } from 'store/actions/app';
import {
    CANCEL_AUTHORISATION,
    CANCEL_AUTHORISATION_FAILURE,
    CANCEL_AUTHORISATION_SUCCESS,
    RESEND_CODE,
    RESEND_CODE_FAILURE,
    RESEND_CODE_SUCCESS,
    START_AUTHORISATION,
    START_AUTHORISATION_FAILURE,
    START_AUTHORISATION_SUCCESS
} from 'store/actions/authorisation.types';

import {
    PREPARE_AUTHORISATION_DETAILS,
    PREPARE_AUTHORISATION_DETAILS_FAILURE,
    PREPARE_AUTHORISATION_DETAILS_SUCCESS,
} from 'store/actions/xs2a.types';


export const onLoadingBegin: Epic = action$ =>
    action$.pipe(
        ofType(
            /* SIGN_ON  */ // handled on the form itself
            PREPARE_AUTHORISATION_DETAILS,
            START_AUTHORISATION,
            CANCEL_AUTHORISATION,
            /* VERIFY_CODE, */ // handled on the form itself
            RESEND_CODE
        ),
        switchMapTo(of(startAppLoading()))
    );

export const onLoadingEnd: Epic = action$ =>
    action$.pipe(
        ofType(
            /* SIGN_ON_SUCCESS, SIGN_ON_FAILURE  */ // handled on the form itself
            PREPARE_AUTHORISATION_DETAILS_SUCCESS, PREPARE_AUTHORISATION_DETAILS_FAILURE,
            START_AUTHORISATION_SUCCESS, START_AUTHORISATION_FAILURE,
            CANCEL_AUTHORISATION_SUCCESS, CANCEL_AUTHORISATION_FAILURE,
            /* VERIFY_CODE_SUCCESS, VERIFY_CODE_FAILURE, */ // handled on the form itself
            RESEND_CODE_SUCCESS, RESEND_CODE_FAILURE
        ),
        switchMapTo(of(endAppLoading()))
    );
