import { LoginState } from 'models/app/store';
import {
    LoginActions,
    SET_LOGIN_STEP,
    SET_LOGIN_LANGUAGE,
} from 'store/actions/login.types';

import { LoginStep } from 'models/app/workflow';
import { createReducer, Handlers } from './reducer';
import { CLEAR_STORE } from '../actions/common.types';

export const initialState: LoginState = {
    loginStep: LoginStep.AUTHORISATION,
    isLoading: false,
    language: 'en-GB',
};

const handlers: Handlers<LoginState, LoginActions> = {
    [SET_LOGIN_LANGUAGE]: (payload: string) => {
        return { language: payload };
    },

    [SET_LOGIN_STEP]: (payload: LoginStep) => {
        return { loginStep: payload };
    },

    [CLEAR_STORE]: () => {
        return initialState;
    }
};

export default createReducer(initialState, handlers);
