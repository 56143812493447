import styled from 'styled-components';
import { space, typography } from 'styled-system';

import { BgColorTransition, ColorTransition } from 'assets/styles/animations/animations.styled';

import { ButtonBaseWithVariants } from './BaseButton';
import { ButtonStylingProps } from '../generic.types';


export const Button = styled(ButtonBaseWithVariants)<ButtonStylingProps>`
    width: ${props => props.width};
    border-radius: 24px;
    border: 1px solid transparent;
    min-width: 172px;
    padding: 12px 24px;
    margin: 0 9px;
    cursor: pointer;
    font-size: 14px;
    ${typography};
    ${space};
    ${BgColorTransition};
    ${ColorTransition};
`;

Button.displayName = 'Button';
