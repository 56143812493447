import { format } from 'date-fns';
import { friendlyFormatIBAN } from 'ibantools';

import { IBAN } from 'models/domain/common';
import { Xs2aAuthorisationId } from 'models/domain/xs2a';


export const formatDateTime = (date: Date | string | number): string => {
    return date ? format(new Date(date), 'yyyy-MM-dd HH:mm:ss') : '';
};

export const extractXs2aAuthorisationId = (token: string | undefined): Xs2aAuthorisationId | undefined => {
    if (token?.length === 32) {
        const regExp = /(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/;
        return token.replace(regExp, '$1-$2-$3-$4-$5');
    } else {
        return undefined;
    }
};

export const formatIBAN = (iban: IBAN): string => {
    return friendlyFormatIBAN(iban);
};

export const formatCurrency = (value: number): string => {
    return value.toFixed(2);
};
