import { RefObject } from 'react';
import { WithTranslation } from 'react-i18next';
import { SpaceProps } from 'styled-system';

import { SmsCode } from 'models/domain/authorisation';
import { SetSmsFieldValuePayload } from 'store/actions/smsCodeForm.types';
import { Button } from 'components/reusable/Buttons/Button';


export type DigitInputProps = Partial<HTMLInputElement> & { error?: boolean, ref: RefObject<HTMLInputElement | null> } & SpaceProps;

export interface FormSeparatorDefinition {
    type: 'separator';
    key: string;
}

export type FormFieldRef = RefObject<HTMLInputElement>;
export type FormFieldTargetRef = RefObject<HTMLInputElement | typeof Button | null>;

export interface FormFieldDefinition {
    type: 'field';
    key: string;
    value: string;
    ownRef: FormFieldRef;
    prevRef: FormFieldTargetRef;
    nextRef: FormFieldTargetRef;
    autoFocus?: boolean;
    doBlur?: boolean;
    pad?: boolean;
}

export type FormItemDefinition = FormSeparatorDefinition | FormFieldDefinition;

export const isFormSeparator = (item: FormItemDefinition): item is FormSeparatorDefinition => item.type === 'separator';

export interface SmsCodeFormStateProps {
    digit1Value: string;
    digit2Value: string;
    digit3Value: string;
    digit4Value: string;
    digit5Value: string;
    digit6Value: string;
    isLoading: boolean;
    hasError: boolean;
    currentDayAuthorisationsCounter: number,
}

export interface SmsCodeFormDispatchProps {
    dispatchSetSmsFieldValue: (data: SetSmsFieldValuePayload) => void,
    dispatchVerifyCode: (code: SmsCode) => void,
    dispatchResendSmsCode: () => void,
    dispatchCancelAuthorisation: () => void,
    dispatchSetSmsCodeFormError: (hasError: boolean) => void;
    dispatchClearSmsCodeForm: () => void;
}

export type SmsCodeFormProps = SmsCodeFormStateProps & SmsCodeFormDispatchProps & WithTranslation;
