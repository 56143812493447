import { connect } from 'react-redux';
import { State } from 'models/app/store';

import { LoginStateProps } from './Login.types';
import { Login } from './Login.layout';

const mapStateToProps = (state: State): LoginStateProps => ({ loginStep: state.login.loginStep });


export default connect(mapStateToProps)(Login);
