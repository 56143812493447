import { css } from 'styled-components';

const duration = '200ms';

const transitions = css`
    .alert-enter {
      opacity: 0;
      transform: scale(0.9);
    }
    
    .alert-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity ${duration}, transform ${duration};
    }
    
    .alert-exit {
      opacity: 1;
    }
    
    .alert-exit-active {
      opacity: 0;
      transform: scale(0.9);
      transition: opacity ${duration}, transform ${duration};
    }
    
    
   .fade-enter {
      opacity: 0;
      transition: opacity ${duration};
    }
    
    .fade-enter-active {
      opacity: 1;
      transition: opacity ${duration};
    }
    
    .fade-exit {
      opacity: 1;
    }
    
    .fade-exit-active {
      opacity: 0;
      transition: opacity ${duration};
    }


   .greyout-enter, .greyout-exit-done, .greyout-appear {
        color: #8A8A8A;
    }

    .greyout-enter-active, .greyout-appear-active {
        color: unset;
        transition: color ${duration};
    }

    .greyout-exit, .greyout-enter-done, .greyout-appear-done {
        color: unset;
    }

    .greyout-exit-active {
        color: #8A8A8A;
        transition: color ${duration};
    }
    
    .fade-overlay-enter {
        opacity: 0.01;
    }
    
    .fade-overlay-enter.fade-overlay-enter-active {
        opacity: 1;
        transition: opacity 50ms ease-in;
    }
    
    .fade-overlay-exit {
        opacity: 1;
    }
    
    .fade-overlay-exit.fade-overlay-exit-active {
        opacity: 0.01;
        transition: opacity 50ms ease-in;
    }
`;

export default transitions;
