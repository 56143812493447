import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { State } from 'models/app/store';

import { PaymentVerificationStateProps } from './PaymentVerification.types';
import PaymentVerification from './PaymentVerification.layout';


const mapStateToProps = (state: State): PaymentVerificationStateProps => ({
    tppName: state.xs2a.authorisation.tppName,
    paymentDetails: state.xs2a.authorisation.paymentDetails!
});

export default connect(mapStateToProps)(withTranslation('pis-consent')(PaymentVerification));
