import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { State } from 'models/app/store';

import { ConsentVerificationStateProps } from './ConsentVerification.types';
import ConsentVerification from './ConsentVerification.layout';


const mapStateToProps = (state: State): ConsentVerificationStateProps => ({
    tppName: state.xs2a.authorisation.tppName,
    consentDetails: state.xs2a.authorisation.consentDetails!
});

export default connect(mapStateToProps)(withTranslation('ais-consent')(ConsentVerification));
