import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Styled from './withLoading.styled';
import { WithLoadingProps, WithInjectedProps } from './withLoading.types';

const withLoading = <P extends object>(WrappedComponent: React.ComponentType<P>, loadingData: WithLoadingProps):
    React.FC<P & WithInjectedProps> => {
    return function withLoadingComponent(props) {
        const { positionLock, transitionClassName, OverlayComponent } = loadingData;
        const { isLoading, ...rest } = props;
        return (
            <Styled.FixPosition positionLock={positionLock}>
                <WrappedComponent {...rest as P} />
                <CSSTransition
                    in={isLoading}
                    mountOnEnter
                    unmountOnExit
                    timeout={50}
                    classNames={transitionClassName || 'fade-overlay'}
                >
                    <OverlayComponent />
                </CSSTransition>
            </Styled.FixPosition>
        );
    };
};

export default withLoading;
