import { State } from 'models/app/store';

import createWithLoading from 'hocs/loading/createWithLoading';
import LoadingOverlaySection from 'components/reusable/Overalys/SectionLoadingOverlay.layout';
import LoginStep from './LoginStep.layout';
import { LoginStepProps } from './LoginStep.types';

const loadingSelector = (state: State) => state.user.isLoading;

export default createWithLoading<LoginStepProps>(LoginStep, { loadingSelector, OverlayComponent: LoadingOverlaySection, positionLock: true });
