import styled, { css, ThemeProps } from 'styled-components';
import { Theme } from 'config/theme';
import { AnimatedLabelProps } from "./AnimatedLabel.types";

const getLabelColor = ({ theme }: ThemeProps<Theme>) => ({ color: theme.colors.font.onDarkLighter });
const getErrorColor = ({ theme }: ThemeProps<Theme>) => ({ color: theme.colors.error.base });
const getInputAndIconColor = ({ theme }: ThemeProps<Theme>) => ({ color: theme.colors.black });

const raisedLabelStyles = css`
    top: -6px;
    font-size: 12px;
    background-color: rgba(255,255,255, 1);
`;

const AnimatedLabel = styled.label<Omit<AnimatedLabelProps, 'text'>>`
    position: absolute;
    top: 20px;
    left: 10px;
    z-index: 4;
    display: block;
    width: max-content;
    font-size: 14px;
    padding: 0 12px 0 10px;
    user-select: none;
    white-space: nowrap;
    cursor: text;
    pointer-events: none;
    ${props => getLabelColor(props)}
    ${props => (props.hasError ? getErrorColor(props) : getInputAndIconColor(props))};
    background-color: rgba(255,255,255, 0);
    transition: all .2s;
    
    ${props => props.hasValue && raisedLabelStyles}
    
    input:-webkit-autofill + & {
      ${raisedLabelStyles};
    }

`;

export default { AnimatedLabel };
