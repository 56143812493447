import { AuthorisationDetails, Xs2aAuthorisationId } from 'models/domain/xs2a';
import { xs2a } from 'services/api/xs2a';
import { SignInCredentials, User } from '../models/domain/user';


class Xs2aService {
    static prepareAuthorisation(xs2aAuthorisationId: Xs2aAuthorisationId): Promise<AuthorisationDetails> {
        return xs2a.prepareAuthorisation(xs2aAuthorisationId);
    }

    static signIn(credentials: SignInCredentials): Promise<User> {
        return xs2a.signIn(credentials);
    }
}

export default Xs2aService;
