import React from 'react';
import logoSymbol from 'assets/img/logo-symbol.svg';
import { WithT } from 'i18next';
import LanguageChanger from './LanguageChanger/LanguageChanger';
import Styled from './Footer.styled';

function Footer({ t }: WithT) {
    const copy = ` ${t('footer.copyRight')} ${new Date().getFullYear()}`;

    return (
        <Styled.LoginFooterOuterWrap>
            <Styled.LoginFooterInnerWrap>
                <Styled.LogoAndCopy>
                    <img src={logoSymbol} alt="OKEO symbol" width={52} height={34} />
                    <Styled.Copyrights>
                        &copy;
                        {copy}
                    </Styled.Copyrights>
                </Styled.LogoAndCopy>

                <Styled.LinksWrap>
                    <Styled.Link href="#">{t('footer.policies')}</Styled.Link>
                    <Styled.Link href="#">{t('footer.securityHints')}</Styled.Link>
                    <Styled.Link href="#">{t('footer.contact')}</Styled.Link>
                </Styled.LinksWrap>

                <LanguageChanger t={t} />

            </Styled.LoginFooterInnerWrap>
        </Styled.LoginFooterOuterWrap>
    );
}

export default Footer;
