import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Dispatch } from 'models/meta/action';
import { setLoginStep } from 'store/actions/login';
import { LoginStep } from 'models/app/workflow';

import { LoginTemporaryBlockade } from './LoginTemporaryBlockade.layout';
import { LoginTemporaryBlockadeDispatchProps } from './LoginTemporaryBlockade.types';

const mapDispatchToProps = (dispatch: Dispatch): LoginTemporaryBlockadeDispatchProps =>
    ({ dispatchSetLoginStep: (loginStep: LoginStep) => dispatch(setLoginStep(loginStep)) });

export default connect(null, mapDispatchToProps)(withTranslation('login')(LoginTemporaryBlockade));
