import styled, { css } from 'styled-components';
import { space } from 'styled-system';

import { WithTheme } from 'config/theme';
import { IconFont } from 'assets/styles/icons/Icons.styled';

import { DigitInputProps } from './SmsCodeForm.types';


const FormWrap = styled.section`
  position: relative;
  margin-bottom: 30px;
  padding: 40px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }: WithTheme) => theme.colors.gray.dark};  
  text-align: center;
  
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: -1px;
    left: -1px;
    width: 43px;
    height: 43px;
    background-color: ${({ theme }: WithTheme) => theme.colors.white};
  }
`;

const Header = styled.h3`
  border: none;
  padding: 0;
  margin-bottom: 29px;
  font-size: 18px;
  font-weight: 400;
`;

const Strong = styled.strong`
  font-size: inherit;
  font-weight: 600;
  color: ${({ theme }: WithTheme) => theme.colors.accent.light};
`;

const SubHeader = styled.h4`
  margin-bottom: 33px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
`;

const Form = styled.form`
  border: none;
  padding: 0;
`;

const Fieldset = styled.div`
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DigitInput = styled.input<DigitInputProps>`
  width: 55px;
  height: 55px;
  ${space}; 
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }: WithTheme) => theme.colors.gray.xdark};  
  border-radius: 2px;
  text-align: center;
  
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  transition: border-color 0.1s ease;
  
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
     margin: 0;
  }
  
   /* Firefox */
  -moz-appearance: textfield; /* stylelint-disable-line property-no-vendor-prefix */
  
  &:active,
  &:focus {
    caret-color:  ${({ theme }: WithTheme) => theme.colors.accent.light};
    border-color: ${({ theme }: WithTheme) => theme.colors.accent.light};  
  }
  
  &::placeholder {
    font-size: 22px;
    font-weight: 600;
    color: ${({ theme }: WithTheme) => theme.colors.gray.dark};  
  }
  
   ${({ error }) => error && css`
     border-color: ${({ theme }: WithTheme) => theme.colors.error.base};  
   `}
`;

const Dash = styled.span`
  display: inline-block;
  margin: 0 12.5px;
  width: 17px;
  height: 2px;
  background-color: ${({ theme }: WithTheme) => theme.colors.gray.xdark};
`;

const SubmitWrap = styled.div`
    display: flex;  
    align-items: center;
    justify-content: center;
    padding: 0 0 30px;
`;

const ErrorWrap = styled.div`
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorMsg = styled.span`
  transition: color 0.2s ease;
  color: ${({ theme }: WithTheme) => theme.colors.error.base};
`;

const ErrorIcon = styled(IconFont)`
  margin-right: 8px;
  font-size: 18px;
  color: ${({ theme }: WithTheme) => theme.colors.error.base};
`;

const SecondaryActionWrap = styled.div`
  margin-bottom: 13px;
`;

export default {
    FormWrap,
    Header,
    Strong,
    SubHeader,
    Form,
    Fieldset,
    DigitInput,
    Dash,
    SubmitWrap,
    ErrorWrap,
    ErrorMsg,
    ErrorIcon,
    SecondaryActionWrap
};
