import React, { FunctionComponent } from 'react';

import Styled from 'assets/styles/global/layout.styled';
import appLogo from 'assets/img/logo.svg';

import Profile from './Profile/Profile';


const NavigationBar: FunctionComponent = () => (
    <Styled.NavigationBar>
        <Styled.NavigationBarOuterWrap>
            <Styled.NavigationBarInnerWrap>
                <img src={appLogo} alt="OKEO" width={95} height={29} />
                <Styled.MainMenuButtonLinks />
                <Styled.MainMenuIconLinks>
                    <Profile />
                </Styled.MainMenuIconLinks>
            </Styled.NavigationBarInnerWrap>
        </Styled.NavigationBarOuterWrap>
    </Styled.NavigationBar>
);

export default NavigationBar;
