import styled from 'styled-components';
import { FooterOuterWrap, FooterInnerWrap } from 'assets/styles/global/layout.styled';
import theme from 'config/theme';

const footerHeight = 85;

const LoginFooterOuterWrap = styled(FooterOuterWrap)`
  height: ${footerHeight}px;
  box-shadow: 0 -3px 6 rgba(0, 0, 0, .16);
  background-color: ${theme.colors.white};
`;

const LoginFooterInnerWrap = styled(FooterInnerWrap)`
  position: relative;
  height: ${footerHeight}px;
`;

const LinksWrap = styled.div`
    position: relative;
    margin: 0 auto;
    display: flex;
    flex:  1 1 100%;
    align-items: center;
    justify-content: center;
`;

const LogoAndCopy = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
`;

const Copyrights = styled.span`
   margin-left: 18px;
   font-size: 14px;
   line-height: 19px;
   color: ${theme.colors.gray.xdark};
   white-space: nowrap;
`;

const Link = styled.a`
   padding: 0 25px;
   font-size: 14px;
   font-weight: 600;
   color: ${theme.colors.gray.xdark};
   cursor: pointer;
   text-decoration: none;
   transition: color .2s ease-in-out;
   white-space: nowrap;
   
   &:hover,
   &:active {
      color: ${theme.colors.accent.light};
   }
`;

const PositionedLanguageChanger = styled.div`
    position: absolute;
    right: 0;
`;

export default {
    LoginFooterOuterWrap,
    LoginFooterInnerWrap,
    LogoAndCopy,
    Copyrights,
    LinksWrap,
    Link,
    PositionedLanguageChanger
};
