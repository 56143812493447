import styled from 'styled-components';
import { Form as ReduxForm } from "redux-form";
import { Button } from 'components/reusable/Buttons/Button';

const Form = styled(ReduxForm)`
  padding: 0 30px;
`;

const CenteredButton = styled(Button)`
  display: block;
  margin: 5px auto;
`;


export default { Form, CenteredButton };
