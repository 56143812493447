import React from 'react';
import Icons from 'assets/styles/icons/icons';

import { FormFieldProps } from './FormField.types';
import Styled from './FormField.styled';

import ErrorMessage from './ErrorMessage/ErrorMessage.layout';
import AnimatedLabel from './AnimatedLabel/AnimatedLabel.layout';
import { TextInput } from './TextInput/TextInput.layout';

export const FormField = ({
    label,
    placeholder,
    input: { onBlur, onChange, value, onFocus },
    meta,
    t,
    id,
    iconName,
    iconTooltip,
    iconOnClick,
    name,
    ...rest
}: FormFieldProps) => {

    const showError = meta.touched && meta.error;
    const isValid = meta.valid && !meta.active && meta.touched;

    return (
        <Styled.FormFieldWithError>
            <Styled.AnimatedLabelWrap hasError={showError}>

                {iconName && (
                    <Styled.FieldIcon
                        icon={Icons[iconName]}
                        data-tip={iconTooltip}
                        onClick={iconOnClick}
                        hasError={showError}
                        isValid={isValid}
                    />
                )}

                <TextInput
                    dataTestId={`${id}-input`}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    showError={showError}
                    placeholder={placeholder}
                    {...rest}
                />

                <AnimatedLabel
                    hasError={showError}
                    hasValue={(value !== undefined && value !== null && !!`${value}`.length) || meta.active || !!placeholder}
                    text={label}
                />

            </Styled.AnimatedLabelWrap>

            {showError && <ErrorMessage errorText={t(meta.error)} dataTestId={id} />}

        </Styled.FormFieldWithError>
    );
};
