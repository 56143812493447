import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { WorkflowStep } from 'models/app/workflow';
import { State } from 'models/app/store';
import { Dispatch } from 'models/meta/action';
import { startAuthorisation } from 'store/actions/authorisation';
import { setWorkflowStep } from 'store/actions/workflow';

import { PaymentAuthorisationDispatchProps, PaymentAuthorisationStateProps } from './PaymentAuthorisation.types';
import PaymentAuthorisation from './PaymentAuthorisation.layout';


const mapStateToProps = (state: State): PaymentAuthorisationStateProps => ({
    tppName: state.xs2a.authorisation.tppName,
    paymentDetails: state.xs2a.authorisation.paymentDetails!,
});

const mapDispatchToProps = (dispatch: Dispatch): PaymentAuthorisationDispatchProps => ({
    dispatchDeclineAuthorisation: () => dispatch(setWorkflowStep(WorkflowStep.DECLINED)),
    dispatchStartAuthorisation: () => dispatch(startAuthorisation())
});


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('pis-consent')(PaymentAuthorisation));
