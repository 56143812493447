import React from 'react';
import { WithT } from 'i18next';
import Styled from './Links.styled';

export default function Links({ t }: WithT) {
    return (
        <Styled.LinksWrap>
            <Styled.Link href="#">{t('securityTexts.checkCertificate')}</Styled.Link>
            <Styled.Dot />
            <Styled.Link href="#">{t('securityTexts.protectCredentials')}</Styled.Link>
            <Styled.Dot />
            <Styled.Link href="#">{t('securityTexts.installAntivirus')}</Styled.Link>
        </Styled.LinksWrap>
    );
}
