import React from 'react';
import Styled from './LoginStep.styled';
import { LoginStepProps } from './LoginStep.types';


export default function LoginStep({ children, pageHeader }: LoginStepProps) {

    return (
        <Styled.LoginPageWrapper>
            <Styled.PageHeader>{pageHeader}</Styled.PageHeader>

            <Styled.LoginStepWrap>
                { children }
            </Styled.LoginStepWrap>

        </Styled.LoginPageWrapper>
    );
}
