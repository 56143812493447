import styled from 'styled-components';

import { IconFont } from 'assets/styles/icons/Icons.styled';

const ToastButton = styled.button`
    position: relative;
    width: 36px;
    height: 36px;
    font-size: 16px;
    transform: translate(-8px, 10px);
`;

const Icon = styled(IconFont)`
    align-self: center;
    width: 36px;
    height: 36px;
    font-size: 16px;
`;

export default { ToastButton, Icon };
