import { ofType } from 'redux-observable';
import { EMPTY, from, fromEvent, of } from 'rxjs';
import {
    catchError,
    delay,
    filter,
    map,
    mapTo,
    mergeMap,
    startWith,
    switchMap,
    switchMapTo,
    take,
    takeUntil,
    tap,
    timeout,
} from 'rxjs/operators';

import { tokenActivityDelay, tokenRefreshTimeout } from 'config/config';
import { Action } from 'models/meta/action';
import { Epic } from 'models/meta/epic';
import { ApiError } from 'models/meta/api';
import { AuthTokens, User } from 'models/domain/user';
import { LoginStep } from 'models/app/workflow';
import { actionErrorToast, clearStore } from 'store/actions/common';
import {
    refreshToken,
    refreshTokenFailure,
    refreshTokenSuccess,
    signOff,
    signOnFailure,
    signOnSuccess,
} from 'store/actions/user';

import {
    REFRESH_TOKEN,
    REFRESH_TOKEN_FAILURE,
    REFRESH_TOKEN_SUCCESS,
    SIGN_OFF,
    SIGN_ON,
    SIGN_ON_SUCCESS,
} from 'store/actions/user.types';

import { setLoginStep } from 'store/actions/login';
import { loadTokens, removeTokens } from 'utils/auth/token';
import { HttpStatus } from 'utils/constants/http-status';


export const onSignOnSuccess: Epic = action$ =>
    action$.pipe(
        startWith(refreshTokenSuccess(loadTokens())),
        ofType(SIGN_ON_SUCCESS),
        mergeMap(() => of(
            setLoginStep(LoginStep.SUCCESS)
        ))
    );

export const onSignOff: Epic = action$ =>
    action$.pipe(
        ofType(SIGN_OFF),
        tap(() => removeTokens()),
        switchMapTo(of(clearStore()))
    );

export const onReceivedTokens: Epic = (action$, _, { apiHttp }) =>
    action$.pipe(
        startWith(refreshTokenSuccess(loadTokens())),
        ofType(SIGN_ON_SUCCESS, REFRESH_TOKEN_SUCCESS),
        filter((action: Action<User>): action is Action<User> & Required<Pick<Action<User>, 'payload'>> => !!action.payload),
        tap((action) => apiHttp.storeTokens(action.payload)),
        delay(tokenActivityDelay),
        switchMap(() => fromEvent(document, 'pointerdown').pipe(
            timeout(tokenRefreshTimeout - tokenActivityDelay),
            takeUntil(action$.pipe(ofType(SIGN_OFF))),
            take(1),
            mapTo(refreshToken()),
            catchError(() => EMPTY)
        ))
    );

export const onTokenRefresh: Epic = (action$, _, { apiHttp }) =>
    action$.pipe(
        ofType(REFRESH_TOKEN),
        switchMap(() => from(apiHttp.refreshTokens(loadTokens())).pipe(
            map((tokens: AuthTokens) => refreshTokenSuccess(tokens)),
            catchError(({ unauthorized }) => of(refreshTokenFailure(unauthorized)))
        ))
    );

export const onTokenRefreshFailed: Epic = action$ =>
    action$.pipe(
        ofType(REFRESH_TOKEN_FAILURE),
        switchMap(({ payload: { unauthorized } }) => unauthorized ? of(signOff()) : EMPTY)
    );


export const onSignOn: Epic = (action$, _, { xs2a }) =>
    action$.pipe(
        ofType(SIGN_ON),
        switchMap(({ payload: { credentials } }) => from(xs2a.signIn(credentials)).pipe(
            map(user => signOnSuccess(user)),
            catchError((error: ApiError) => {
                const blockedUserMessages = ['blockedUser', 'invalidLoginAndBlockade'];

                const loginStepActions = blockedUserMessages.includes(error.message)
                    ? [setLoginStep(LoginStep.BLOCKED_USER)]
                    : [];

                const loginError = [HttpStatus.FORBIDDEN, HttpStatus.UNAUTHORIZED].includes(error.status || 0) && !blockedUserMessages.includes(error.message);
                const toastActions = loginError ? [actionErrorToast('login', error.message)] : error.actions;

                const actions = loginStepActions.length ? loginStepActions : toastActions;

                return of(signOnFailure(), ...actions);
            })
        ))
    );
