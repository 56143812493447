import { css } from 'styled-components';

const reset = css`
* {
    outline: none;
    box-sizing: border-box;
}

*[data-tip]  {
    cursor: help;
}

*:not(i) {
    outline: none;
    box-sizing: border-box;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

button {
    font-family: inherit;
    border: 1px solid transparent;
    background-color: transparent;
    cursor: pointer;

    &:disabled {
        pointer-events: none;
        cursor: not-allowed;
    }

    &:focus {
        outline: 0;
    }
}

a {
    outline: 0;
}

ul, ol, li {
    list-style: none;
}

input::-ms-clear {
    display: none;
}


h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
`;

export default reset;
