import styled from 'styled-components';

const ButtonsWrap = styled.section`
    display: flex;  
    align-items: center;
    justify-content: center;
    padding: 60px 0 30px;
`;

export default ButtonsWrap;
