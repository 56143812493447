import { LoginStep } from 'models/app/workflow';

import {
    SET_LOGIN_STEP,
    SET_LOGIN_LANGUAGE,
    SetLoginStep,
    SetLoginLanguage
} from './login.types';

export const setLoginStep = (loginStep: LoginStep): SetLoginStep => ({ type: SET_LOGIN_STEP, payload: loginStep });
export const setLoginLanguage = (locale: string): SetLoginLanguage => ({ type: SET_LOGIN_LANGUAGE, payload: locale });
