import { ThemeProps } from 'styled-components';

const baseColors = {
    white: '#fff',
    black: '#000'
};

const icon = {
    // Below filter attributes works for BLACK icons
    green: {
        light: 'invert(88%) sepia(27%) saturate(5835%) hue-rotate(58deg) brightness(99%) contrast(79%)',
        base: 'invert(62%) sepia(19%) saturate(1342%) hue-rotate(51deg) brightness(85%) contrast(86%)',
        dark: 'invert(34%) sepia(51%) saturate(584%) hue-rotate(50deg) brightness(98%) contrast(91%)'
    },
    white: 'invert(100%) sepia(20%) saturate(27%) hue-rotate(102deg) brightness(106%) contrast(106%)',
    gray: {
        xlight: 'invert(98%) sepia(6%) saturate(22%) hue-rotate(259deg) brightness(104%) contrast(95%)',
        light: 'invert(100%) sepia(3%) saturate(136%) hue-rotate(213deg) brightness(118%) contrast(91%)',
        base: 'invert(100%) sepia(5%) saturate(526%) hue-rotate(253deg) brightness(109%) contrast(80%)',
        dark: 'invert(90%) sepia(0%) saturate(0%) hue-rotate(202deg) brightness(95%) contrast(91%)',
        xdark: 'invert(55%) sepia(1%) saturate(0%) hue-rotate(358deg) brightness(99%) contrast(97%)',
        ash: 'invert(51%) sepia(7%) saturate(309%) hue-rotate(201deg) brightness(97%) contrast(89%)'
    }
};

const paletteGreen = {
    ...baseColors,
    gray: {
        whitish: '#fafafa',
        xlight: '#F9F9F9',
        light: '#F4F4F4',
        base: '#E6E6E6',
        dark: '#D1D1D1',
        darker: '#C6CDD0',
        darkest: '#969696',
        xdark: '#8A8A8A',
        ash: '#7f7f7f'
    },
    green: {
        lighter: '#38990d1f',
        light: '#7FBF4F',
        base: '#659F39',
        dark: '#457221',
    },
    font: {
        onAccent: '#fff', // this should be use only as font / icon / stroke color placed on accent color
        onDark: '#fff', // this should be use only as font / icon / stroke color placed on accent color
        onPrimaryLight: '#8A8A8A',
        onPrimaryDark: '#fff'
    },
    orange: {
        lightest: '#EAA51B',
        base: '#EC8B00',
        darker: '#D96D03'
    },
    red: {
        lightest: '#fde2e4',
        base: '#D93E47',
        darker: '#BA1010'
    },
    blue: {
        lightest: '#fde2e4',
        base: '#32A1C6',
        darker: '#1F7997'
    }
};

const themeGreen = {
    white: baseColors.white,
    black: baseColors.black,
    primary: paletteGreen.gray.light,
    secondary: paletteGreen.gray.xlight,
    accent: paletteGreen.green,
    warn: paletteGreen.orange,
    error: paletteGreen.red,
    info: paletteGreen.blue,
    gray: paletteGreen.gray,
    others: { orange: paletteGreen.orange.base },
    font: {
        onAccent: baseColors.white, // this should be use only as font / icon / stroke color placed on accent color
        onDark: baseColors.white, // this should be use only as font / icon / stroke color placed on accent color
        onDarkLight: paletteGreen.gray.darkest, // this should be use only as font / icon / stroke color placed on accent color
        onDarkLighter: paletteGreen.gray.ash, // this should be use only as font / icon / stroke color placed on accent color
        onPrimaryLighter: paletteGreen.gray.xdark,
        onPrimaryDarker: baseColors.black,
        onMenuButtonHover: paletteGreen.green.light
    }
};

const fontWeights = {
    normal: 400,
    semiBold: 600,
    bold: 700
};

export const theme = {
    fontSizes: [12, 14, 16, 24, 32, 40, 50, 48, 64, 96, 128],
    fontWeights,
    space: [0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 96, 128, 256],
    colors: themeGreen,
    iconPalette: icon,
    boxShadow: { containerBlurred: '0px 3px 6px #0000001A' }
};

export type Theme = typeof theme;

export type WithTheme = ThemeProps<Theme>;
export default theme;
