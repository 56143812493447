import React from 'react';
import Icons from 'assets/styles/icons/icons';
import { HeadingProps } from './Heading.types';
import Styled from './Heading.styled';

export default function Heading({
    pageHeader,
    pageSubheader,
    iconName,
    iconColor
}: HeadingProps) {
    return (
        <Styled.HeadingWrap>
            <Styled.HeadingTitle>{pageHeader}</Styled.HeadingTitle>
            <Styled.HeadingIcon icon={Icons[iconName]} color={`${iconColor}`} />
            <Styled.HeadingDescription>{pageSubheader}</Styled.HeadingDescription>
        </Styled.HeadingWrap>
    );
}
