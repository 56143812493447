import { ToastOptions } from 'react-toastify/dist/types';

import { ResultType } from 'models/domain/common';
import Icons from "assets/styles/icons/icons";


interface ToastType {
    borderColor: string;
    dataTestId: string;
    color: string;
    iconName: keyof typeof Icons,
    closeable: boolean;
}

type ToastTypes = Record<ResultType, ToastType>;

export const toastTypes: ToastTypes = {
    INFO: {
        borderColor: 'info.base',
        dataTestId: 'toast-message-info',
        color: 'gray.xdark',
        iconName: 'infoCircle',
        closeable: false
    },
    WARN: {
        borderColor: 'warn.base',
        dataTestId: 'toast-message-warn',
        color: 'warn.base',
        iconName: 'warning',
        closeable: true
    },
    DANGER: {
        borderColor: 'error.base',
        dataTestId: 'toast-message-danger',
        color: 'error.base',
        iconName: 'closeCircle',
        closeable: true
    },
    SUCCESS: {
        borderColor: 'accent.light',
        dataTestId: 'toast-message-success',
        color: 'accent.light',
        iconName: 'checkMarkCircle',
        closeable: true
    }
};

export const defaultToastOptions: ToastOptions = {
    hideProgressBar: true,
    draggable: false,
    closeOnClick: false
};
