import React from 'react';
import Heading from 'components/reusable/authorization/Heading/Heading.layout';
import PaymentsDetails from 'components/reusable/authorization/PaymentDetails/PaymentDetails.layout';
import { Button } from 'components/reusable/Buttons/Button';
import ButtonsWrap from 'components/reusable/Buttons/ButtonsWrap.styled';

import { PaymentAuthorisationProps } from './PaymentAuthorisation.types';


export default function PaymentAuthorisation(props: PaymentAuthorisationProps) {
    const { t, dispatchDeclineAuthorisation, dispatchStartAuthorisation } = props;

    return (
        <main>
            <Heading
                pageHeader={t('pageHeader')}
                iconName="key"
                iconColor="accent.light"
                pageSubheader={t('step1pageSubheader')}
            />

            <PaymentsDetails {...props} />

            <ButtonsWrap>
                <Button onClick={() => dispatchDeclineAuthorisation()} variant="light">
                    {t('buttons.decline.text')}
                </Button>
                <Button onClick={() => dispatchStartAuthorisation()}>
                    {t('buttons.authorizePayment.text')}
                </Button>
            </ButtonsWrap>
        </main>
    );
}
