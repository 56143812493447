import styled from 'styled-components';
import { WithTheme } from 'config/theme';

const InfoMessage = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    color: ${({ theme }: WithTheme) => theme.colors.gray.xdark}
`;

const SuccessSubHeader = styled.div`
  margin: 20px auto 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  color: ${({ theme }: WithTheme) => theme.colors.accent.light}
`;

export default { InfoMessage, SuccessSubHeader };
