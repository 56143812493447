import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { apiURL, defaultRoutePath, openBankingURL } from 'config/config';
import { Action, Dispatch } from 'models/meta/action';
import { ProfileId } from 'models/domain/common';
import { ApiError, loginUrl, refreshTokenUrl, RequestConfig, Response } from 'models/meta/api';
import { AuthTokens, User } from 'models/domain/user';
import { signOff } from 'store/actions/user';
import { actionErrorToast, actionWarnToast } from 'store/actions/common';
import { HttpStatus } from 'utils/constants/http-status';
import {
    AuthTokensConsumer,
    AuthTokensProvider,
    doesTokenNeedRefresh,
    isTokenExpired,
    loadTokens,
    saveTokens
} from 'utils/auth/token';


function setHeaders(headers: any, authToken?: string): void {
    headers.Authorization = authToken ? `Bearer ${authToken}` : '';
    headers['cache-control'] = `no-cache`;
}

const createErrorActions = (error: ApiError): Action[] => {
    if (!error.authentication && (error.status === HttpStatus.UNAUTHORIZED || error.status === HttpStatus.FORBIDDEN)) {
        const toast = actionErrorToast('common', 'genericUnauthorizedMessage');

        if (error.status === HttpStatus.UNAUTHORIZED) {
            return [toast, signOff()];

        } else {
            return [toast];
        }

    } else if (error.status === HttpStatus.SERVICE_UNAVAILABLE || error.status === HttpStatus.GATEWAY_TIMEOUT) {
        return [actionWarnToast('common', 'offlineMessage')];

    } else if (error.status === HttpStatus.TOO_MANY_REQUESTS) {
        return [actionWarnToast('common', 'tooManyRequests')];

    } else {
        return [actionErrorToast()];
    }
};

const createApiError = (axiosError: AxiosError): ApiError => {
    const data = axiosError.response?.data;
    const error = new Error(data?.message || data || '') as ApiError;

    error.status = axiosError.response?.status || HttpStatus.IM_A_TEAPOT;
    error.authentication = axiosError.config.url === loginUrl;

    error.handled = !(axiosError.config as RequestConfig).noErrorHandling;
    error.actions = createErrorActions(error);

    return error;
};

function createAuthorizationAxiosError(config: AxiosRequestConfig): AxiosError {
    const response: AxiosResponse = {
        data: undefined,
        status: HttpStatus.UNAUTHORIZED,
        statusText: 'UNAUTHORIZED',
        headers: config.headers,
        config
    };

    return {
        name: 'TOKEN_EXPIRED',
        message: `Authorization token expired`,
        config,
        response,
        isAxiosError: true,
        toJSON: () => ({})
    };
}

export interface HttpService {
    setProfileId(profileId: ProfileId): void;
    storeTokens(user: User): void;
    refreshTokens(tokens: Omit<AuthTokens, 'authTokenExpiration'>): Promise<AuthTokens>;
}

class HttpServiceInstance implements HttpService {

    private axios!: AxiosInstance;

    private getAuthTokens: AuthTokensProvider = loadTokens;

    private setAuthTokens: AuthTokensConsumer = saveTokens;

    private dispatch: Dispatch;

    public constructor(private baseUrl: string | undefined = apiURL) {
        this.reset();
    }

    public reset(): void {
        const headers = { 'Content-Type': 'application/json' };
        this.axios = Axios.create({ baseURL: this.baseUrl || defaultRoutePath, headers });
        this.axios.interceptors.request.use(config => this.updateHeaders(config));
        this.axios.interceptors.response.use(response => response, (error: AxiosError) => this.handleResponseError(createApiError(error)));
    }

    public configure(dispatch: Dispatch, mock?: (mockApi: MockAdapter) => void): HttpServiceInstance {
        this.reset();

        this.dispatch = dispatch;

        if (mock) {
            mock(new MockAdapter(this.axios));
        }

        return this;
    }

    private request<T, R = T | Error>(method: 'get' | 'delete' | 'post' | 'put', url: string, config?: RequestConfig & { data?: any }): Promise<R> {
        const responseMapper = config?.raw
            ? (response: AxiosResponse) => ({ data: response?.data, status: response?.status, headers: response?.headers }) as Response<R>
            : (response: AxiosResponse) => response?.data;

        return this.axios.request({ ...config, method, url }).then(responseMapper);
    }

    public get<R>(url: string, config?: RequestConfig): Promise<R> {
        return this.request('get', url, config);
    }

    public delete<R>(url: string, data?: any, config?: RequestConfig): Promise<R> {
        return this.request('delete', url, { ...config, data });
    }

    public put<R>(url: string, data: any, config?: RequestConfig): Promise<R> {
        return this.request('put', url, { ...config, data });
    }

    public post<R>(url: string, data: any, config?: RequestConfig): Promise<R> {
        return this.request('post', url, { ...config, data });
    }

    public getToList<R>(url: string, config?: RequestConfig): Promise<R[]> {
        return this.get<R[]>(url, config).then(result => result || [], () => []);
    }

    public deleteToList<R>(url: string, config?: RequestConfig): Promise<R[]> {
        return this.delete<R[]>(url, config).then(result => result || [], () => []);
    }

    public putToList<R>(url: string, data: any, config?: RequestConfig): Promise<R[]> {
        return this.put<R[]>(url, data, config).then(result => result || [], () => []);
    }

    public postToList<R>(url: string, data: any, config?: RequestConfig): Promise<R[]> {
        return this.post<R[]>(url, data, config).then(result => result || [], () => []);
    }

    public setProfileId(profileId: ProfileId): void {
        this.axios.defaults.headers.common['X-Okeo-pID'] = profileId;
    }

    // eslint-disable-next-line class-methods-use-this
    public storeTokens(user: User): void {
        const { authToken, authTokenExpiration, refreshToken } = user;
        if (authToken !== undefined && authTokenExpiration !== undefined && refreshToken !== undefined) {
            saveTokens({ authToken, authTokenExpiration, refreshToken });
        }
    }

    private handleResponseError(error: ApiError): Promise<ApiError> {
        if (error.handled) {
            const errorActions = createErrorActions(error);
            errorActions.forEach(action => this.dispatch(action));
        }

        return Promise.reject(error);
    }

    private async updateHeaders(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
        const { authToken, refreshToken, authTokenExpiration } = this.getAuthTokens();

        config = { ...config };

        setHeaders(config.headers, authToken);
        setHeaders(this.axios.defaults.headers, authToken);

        if (authToken) {
            const isExpired = isTokenExpired(authToken, authTokenExpiration);

            if (isExpired && config.url !== loginUrl) {
                return Promise.reject(createAuthorizationAxiosError(config));
            }

            const needsRefresh = doesTokenNeedRefresh(authToken, authTokenExpiration);

            if (needsRefresh && config.url !== refreshTokenUrl) {
                await this.refreshTokens({ authToken, refreshToken });
            }
        }

        return Promise.resolve(config);
    }

    public async refreshTokens(tokens: Omit<AuthTokens, 'authTokenExpiration'>): Promise<AuthTokens> {
        const { refreshToken } = tokens;

        const response = await this.post<AuthTokens>(refreshTokenUrl, refreshToken)
            .then(tokens => {
                this.setAuthTokens(tokens);
                return tokens;
            });

        return response;
    }

}

export const ApiHttpService = new HttpServiceInstance();
export const Xs2aHttpService = new HttpServiceInstance(openBankingURL);

export const createApiHttpService = (): HttpService => ({
    setProfileId: ApiHttpService.setProfileId.bind(ApiHttpService),
    storeTokens: ApiHttpService.storeTokens.bind(ApiHttpService),
    refreshTokens: ApiHttpService.refreshTokens.bind(ApiHttpService)
});

export const createXs2aHttpService = (): HttpService => ({
    setProfileId: Xs2aHttpService.setProfileId.bind(Xs2aHttpService),
    storeTokens: Xs2aHttpService.storeTokens.bind(Xs2aHttpService),
    refreshTokens: Xs2aHttpService.refreshTokens.bind(Xs2aHttpService)
});
