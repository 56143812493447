import React from 'react';
import 'react-toastify/dist/ReactToastify.min.css';

import { AuthorisationType, WorkflowStep } from 'models/app/workflow';
import Login from 'components/pages/Login/Login';
import ConsentAuthorisation from 'components/pages/ConsentAuthorisation/ConsentAuthorisation';
import ConsentVerification from 'components/pages/ConsentVerification/ConsentVerification';
import ConsentDeclined from 'components/pages/ConsentDeclined/ConsentDeclined';
import ConsentFinalised from 'components/pages/ConsentFinalised/ConsentFinalised';
import PaymentAuthorisation from 'components/pages/PaymentAuthorisation/PaymentAuthorisation';
import PaymentVerification from 'components/pages/PaymentVerification/PaymentVerification';
import PaymentDeclined from 'components/pages/PaymentDeclined/PaymentDeclined';
import PaymentFinalised from 'components/pages/PaymentFinalised/PaymentFinalised';
import AuthorisationFailed from 'components/pages/AuthorisationFailed/AuthorisationFailed';
import { extractXs2aAuthorisationId } from 'utils/text-formatters';

import { AppProps } from './App.types';
import AuthorisedLayout from './AuthorisedLayout/AuthorisedLayout';


const renderStep = (authorisationType: AuthorisationType, paymentElement: JSX.Element, consentElement: JSX.Element): JSX.Element => {
    const conditions: Record<AuthorisationType, JSX.Element> = {
        UNKNOWN: <AuthorisationFailed />,
        LOGIN: <AuthorisationFailed />,
        PAYMENT: paymentElement,
        CONSENT: consentElement,
    };

    return conditions[authorisationType ?? AuthorisationType.UNKNOWN];
};

const renderWorkflowStep = (authorisationType: AuthorisationType, workflowStep: WorkflowStep): JSX.Element => {
    const conditions: Record<WorkflowStep, JSX.Element> = {
        INIT: <></>,
        BAD_REQUEST: <h1>400 BAD REQUEST</h1>,
        LOGIN: <Login />,

        AUTHORISATION: <AuthorisedLayout>{renderStep(authorisationType, <PaymentAuthorisation />, <ConsentAuthorisation />)}</AuthorisedLayout>,
        VERIFICATION: <AuthorisedLayout>{renderStep(authorisationType, <PaymentVerification />, <ConsentVerification />)}</AuthorisedLayout>,
        FINALISED: <AuthorisedLayout>{renderStep(authorisationType, <PaymentFinalised />, <ConsentFinalised />)}</AuthorisedLayout>,
        DECLINED: <AuthorisedLayout>{renderStep(authorisationType, <PaymentDeclined />, <ConsentDeclined />)}</AuthorisedLayout>,
    };

    return conditions[workflowStep ?? WorkflowStep.INIT];
};

const App: React.FC<AppProps> = ({ isAuthenticated, ...props }) => {
    const { dispatchInitWorkflow, dispatchFinishWorkflow, authorisationType, workflowStep } = props;
    const { token } = props.match.params;

    React.useEffect(() => {
        const xs2aAuthorisationId = extractXs2aAuthorisationId(token);
        xs2aAuthorisationId ? dispatchInitWorkflow(xs2aAuthorisationId) : dispatchFinishWorkflow();
    }, []);

    const workflowComponent = renderWorkflowStep(authorisationType, workflowStep);

    return <div className="appInnerWrapper">{workflowComponent}</div>;
};

export default App;
