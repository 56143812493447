import { WithTranslation } from 'react-i18next';
import * as yup from "yup";
import { FormErrors } from 'redux-form';

export const LOGIN_FORM = 'login';

const loginMinLength = 3;
const passwordMinLength = 8;


export const loginShema = {
    login: yup.string().min(loginMinLength, `login:validationMsg.minimumLength|${loginMinLength}`).required(`login:validationMsg.fieldMandatory`),
    password: yup.string().min(passwordMinLength, `login:validationMsg.minimumLength|${passwordMinLength}`).required(`login:validationMsg.fieldMandatory`)
};

export const schema = yup.object(loginShema);

export interface LoginStateProps {
    fieldsValues: Record<string, string>,
    formErrors: FormErrors,
    loginStep: string,
}

export interface LoginDispatchProps {
    dispatchSignIn: (login: string, password: string) => void;
}

export type LoginProps = LoginStateProps & LoginDispatchProps & WithTranslation;
