import { css } from 'styled-components';
import theme, { WithTheme } from 'config/theme';
import { ScrollBar } from 'assets/styles/mixins/scrollbar';

const dropdown = css<WithTheme>`
.dropdownSelectOuterWrap {
    width: 100%;
}
    
.react-dropdown-select-dropdown.react-dropdown-select-dropdown {
    max-height: 200px;
    overflow-y: auto;
    margin-left: 1px;
    display: block;
    padding: 0;
    border-color: ${theme.colors.gray.base};
    border-top: 0;
    border-radius: 0;
    background-color: ${theme.colors.white};
    box-shadow: none;
    width: auto;
    ${ScrollBar};

    .react-dropdown-select-no-data {
        height: 40px;
        color: ${theme.colors.black};
    }
}

.react-dropdown-select-item.react-dropdown-select-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0 18px;
    border-top: none;
    border-bottom: none;
    text-align: left;
    color: ${theme.colors.black};
    font-weight: 400;
    white-space: nowrap;
    background-color: ${theme.colors.white};

    & + .react-dropdown-select-item {
        border-top: 1px solid ${theme.colors.gray.base};
    }

    &:hover,
    &.react-dropdown-select-item-active {
        background-color: ${theme.colors.gray.xlight};
    }  

    &.react-dropdown-select-item-selected {
        border-bottom: 0;
        text-align: left;
        color: ${theme.colors.black};
        background-color: rgba(127, 191, 79, .3);
    }

    &.react-dropdown-select-item-disabled {
        background-color: #777;
        color: #ccc;
    }
}
`;

export default dropdown;
