import React from 'react';
import i18next, { TFunction } from 'i18next';
import { SelectItemRenderer } from 'react-dropdown-select';
import { Dropdown } from 'components/reusable/Dropdown/Dropdown.layout';

import Styled from './LanguageChanger.styled';
import { LanguageChangerProps, LanguageOption, languageOptions } from './LanguageChanger.types';


const createCustomItemRenderer = (t: TFunction) => (select: SelectItemRenderer<LanguageOption>) => {
    const { item, methods, props } = select;

    return (
        <span
            role="option"
            aria-disabled={props?.disabled}
            key={`${item?.key}`}
            tabIndex={-1}
            aria-label={item?.text}
            aria-selected={methods?.isSelected(item)}
            onKeyPress={props?.disabled ? undefined : () => methods?.addItem(item)}
            onClick={() => methods?.addItem(item)}
            className={`react-dropdown-select-item ${methods?.isSelected(item) ? `react-dropdown-select-item-selected` : ''}`}
        >
            <span>{t(item?.text)}</span>
            {item?.src && <Styled.FlagIcon src={item.src} alt="" />}
        </span>
    );
};

const createCustomContentRenderer = (t: TFunction) => (select: SelectItemRenderer<LanguageOption>) => {
    const firstValue = select.state.values[0];
    return (
        <>
            <span>{t(firstValue.text)}</span>
            <Styled.FlagIcon src={firstValue.src} alt="" />
        </>
    );
};

export const LanguageChanger: React.FC<LanguageChangerProps> = props => {
    const { language, dispatchSetLoginLanguage, t } = props;

    const onOptionChange = (optionValue: string) => {
        if (optionValue !== language) {
            i18next.changeLanguage(optionValue);
            dispatchSetLoginLanguage(optionValue);
        }
    };

    const input = { onChange: onOptionChange, value: language };
    const contentRenderer = createCustomContentRenderer(t);
    const itemRenderer = createCustomItemRenderer(t);

    return (
        <Styled.LanguageChangerWrap>
            <Dropdown
                options={languageOptions}
                input={input}
                contentRenderer={contentRenderer}
                itemRenderer={itemRenderer}
                dropdownPosition="top"
            />
        </Styled.LanguageChangerWrap>
    );
};

export default LanguageChanger;
