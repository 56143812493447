import React from 'react';

import Attribute from 'components/reusable/authorization/Attribute/Attribute.layout';
import { formatIBAN, formatCurrency } from 'utils/text-formatters';

import { PaymentDetailsProps } from './PaymentDetails.types';
import Styled from './PaymentDetails.styled';

export default function PaymentsDetails({
    t,
    tppName,
    paymentDetails
}: PaymentDetailsProps) {

    return (
        <main>
            <Styled.RowAttribute>
                <Attribute value={tppName} label={t('attributes.paymentInitiatedBy.label')} tooltip={t('attributes.paymentInitiatedBy.tooltip')} />
            </Styled.RowAttribute>

            <Styled.RowAttribute>
                <Attribute
                    value={formatIBAN(paymentDetails.fromAccountIban)}
                    label={t('attributes.fromAccount.label')}
                    name={paymentDetails.fromAccountName}
                    hint={t('attributes.fromAccount.balance', { currency: paymentDetails.fromAccountCurrency })}
                />
            </Styled.RowAttribute>

            <Styled.RowAttribute>
                <Attribute value={formatIBAN(paymentDetails.toAccountIban)} label={t('attributes.toAccount.label')} />
            </Styled.RowAttribute>

            <Styled.RowAttribute>
                <Attribute
                    value={`${formatCurrency(paymentDetails.amount.amount)} ${paymentDetails.amount.currency}`}
                    label={t('attributes.amount.label')}
                />
            </Styled.RowAttribute>

            <Styled.RowAttribute>
                <Attribute value={paymentDetails.beneficiary} label={t('attributes.beneficiary.label')} />
            </Styled.RowAttribute>

            <Styled.RowAttribute>
                <Attribute value={paymentDetails.description} label={t('attributes.description.label')} />
            </Styled.RowAttribute>
        </main>
    );
}
