import React from 'react';

import Heading from 'components/reusable/authorization/Heading/Heading.layout';
import ConsentDetails from 'components/reusable/authorization/ConsentDetails/ConsentDetails.layout';
import { Button } from 'components/reusable/Buttons/Button';
import ButtonsWrap from 'components/reusable/Buttons/ButtonsWrap.styled';

import { ConsentAuthorisationProps } from './ConsentAuthorisation.types';


export default function ConsentAuthorisation(props: ConsentAuthorisationProps) {
    const { t, dispatchStartAuthorisation, dispatchDeclineAuthorisation } = props;

    return (
        <main>
            <Heading
                pageHeader={t('pageHeader')}
                iconName="key"
                iconColor="accent.light"
                pageSubheader={t('step1pageSubheader')}
            />

            <ConsentDetails {...props} />

            <ButtonsWrap>
                <Button onClick={() => dispatchDeclineAuthorisation()} variant="light">{t('common:buttons.decline.text')}</Button>
                <Button onClick={() => dispatchStartAuthorisation()}>{t('buttons.grantConsent.text')}</Button>
            </ButtonsWrap>
        </main>
    );
}
