import styled from 'styled-components';
import theme from 'config/theme';

const ProfileWrap = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 380px;
    align-items: center;
`;

const ProfileLabel = styled.div`
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
    color: ${theme.colors.accent.light};
`;

const ProfileName = styled.div`
    margin-bottom: 3px;
    font-size: 16px;
    color: ${theme.colors.black};
`;

const ProfileCompanyName = styled.div`
    font-size: 16px;
    font-weight: 700;
    color: ${theme.colors.black};
`;

export default {
    ProfileWrap,
    ProfileLabel,
    ProfileName,
    ProfileCompanyName,
};
