import { DeepPartial } from 'redux';
import styled, { css, ThemedStyledFunction } from 'styled-components';
import { variant, VariantArgs } from 'styled-system';

import { WithTheme } from 'config/theme';

import { ButtonProps } from './Button.types';

export const buttonBoxShadowVoffset = 4;

const lightBgAndAccentedColor = {
    bg: 'accent.light',
    color: 'font.onAccent'
};

const light = {
    color: 'accent.dark',
    bg: 'secondary',
    '&:hover, &:focus': lightBgAndAccentedColor,
    '&:active': {
        bg: 'accent.light',
        color: 'font.onAccent'
    },
    '&:disabled, &[disabled]': { color: 'font.onPrimaryLighter' }
};

const variantArgs: VariantArgs & { variants: Record<string, DeepPartial<typeof light>> } = {

    variants: {
        white: {
            color: 'accent.dark',
            bg: 'white',
            '&:hover, &:focus': { bg: 'secondary' },
            '&:active': {
                bg: 'secondary',
                color: 'accent.dark'
            },
            '&:disabled, &[disabled]': { color: 'font.onPrimaryLighter' }
        },
        light,
        accent: {
            color: 'font.onAccent',
            bg: 'accent.light',
            '&:hover, &:focus': {
                bg: 'accent.base',
                color: 'font.onAccent'
            },
            '&:active': {
                bg: 'accent.base',
                color: 'font.onAccent'
            },
            '&:disabled, &[disabled]': {
                color: 'font.onDark',
                bg: 'gray.xdark'
            }
        },
        error: {
            color: 'font.onAccent',
            bg: 'error.base',
            '&:hover, &:focus': {
                bg: 'error.darker',
                color: 'font.onAccent'
            },
            '&:active': {
                bg: 'error.darker',
                color: 'font.onAccent'
            },
            '&:disabled, &[disabled]': {
                color: 'font.onDark',
                bg: 'gray.xdark'
            }
        },
        warn: {
            color: 'font.onAccent',
            bg: 'warn.base',
            '&:hover, &:focus': {
                bg: 'warn.darker',
                color: 'font.onAccent'
            },
            '&:active': {
                bg: 'warn.darker',
                color: 'font.onAccent'
            },
            '&:disabled, &[disabled]': {
                color: 'font.onDark',
                bg: 'gray.xdark'
            }
        },

        info: {
            color: 'font.onAccent',
            bg: 'info.base',
            '&:hover, &:focus': {
                bg: 'info.darker',
                color: 'font.onAccent'
            },
            '&:active': {
                bg: 'info.darker',
                color: 'font.onAccent'
            },
            '&:disabled, &[disabled]': {
                color: 'font.onDark',
                bg: 'gray.xdark'
            }
        },
    }
};

const buttonBaseStyle = ({ theme }: Partial<WithTheme>) => css`
    box-shadow: 0 ${buttonBoxShadowVoffset}px 0 0 rgba(220, 220, 220, 1);
    border: 1px solid transparent;
    cursor: pointer;
    text-align: center;
    font-size: ${theme?.fontSizes[1] || '1rem'};
    font-weight: ${theme?.fontWeights.semiBold || '600'};
`;

const createStyledButton: ThemedStyledFunction<'button', WithTheme & ButtonProps> = styled('button');

export const ButtonBaseWithVariants = createStyledButton(buttonBaseStyle, variant(variantArgs));

ButtonBaseWithVariants.defaultProps = {
    variant: 'accent',
    width: 'auto'
};
