import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Xs2aAuthorisationId } from 'models/domain/xs2a';
import { State } from 'models/app/store';
import { Dispatch } from 'models/meta/action';
import { prepareAuthorisationDetails } from 'store/actions/xs2a';

import { LoginSuccessDispatchProps, LoginSuccessStateProps } from './LoginSuccess.types';
import { LoginSuccess } from './LoginSuccess.layout';


const mapStateToProps = (state: State): LoginSuccessStateProps => ({ xs2aAuthorisationId: state.workflow.xs2aAuthorisationId });

const mapDispatchToProps = (dispatch: Dispatch): LoginSuccessDispatchProps =>
    ({ dispatchPrepareAuthorisationDetails: (xs2aAuthorisationId: Xs2aAuthorisationId) => dispatch(prepareAuthorisationDetails(xs2aAuthorisationId)) });


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('login')(LoginSuccess));
