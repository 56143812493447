import React from 'react';

import blockedUser from 'assets/img/blocked-user.svg';

import { PageSubHeader, DecorationImage, SecondaryActionWrap } from 'components/pages/Login/LoginStep/LoginStep.styled';
import LoginStep from 'components/pages/Login/LoginStep/LoginStep';
import TextButton from 'components/reusable/Buttons/TextButton.styled';
import { LoginBlockedUserProps } from './LoginBlockedUser.types';


export const LoginBlockedUser = ({ t }: LoginBlockedUserProps) => {

    return (
        <LoginStep pageHeader={t('pageHeader.accessDenied')}>
            <PageSubHeader>{t('blockedUserWindow.header')}</PageSubHeader>

            <DecorationImage src={blockedUser} alt="" width={136} height={160} />

            <SecondaryActionWrap>
                {t('blockedUserWindow.text')}
                <TextButton onClick={() => {}}>{t('buttons.unlockProcedure.text')}</TextButton>
            </SecondaryActionWrap>

        </LoginStep>
    );
};
