import React, { FunctionComponent } from 'react';

import Tooltip from 'components/reusable/Tooltip/Tooltip.layout';

import NavigationBar from './NavigationBar/NavigationBar.layout';
import Footer from './Footer/Footer.layout';
import Styled from './AuthorisedLayout.styled';

const AuthorisedLayout: FunctionComponent = ({ children }) => {
    return (
        <>
            <NavigationBar />

            <Styled.AuthorisedMainContentWrap>
                <Styled.AuthorisedMainContent>
                    { children }
                </Styled.AuthorisedMainContent>
            </Styled.AuthorisedMainContentWrap>

            <Footer />

            <Tooltip />
        </>

    );
};

export default AuthorisedLayout;
