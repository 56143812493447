import { WithT } from 'i18next';

import { DropdownOption } from 'models/app/common';
import GB from 'assets/img/flags/GB.svg';
import PL from 'assets/img/flags/PL.svg';


export interface LanguageChangerStateProps {
    language: string,
}

export interface LanguageChangerDispatchProps {
    dispatchSetLoginLanguage: (language: string) => void;
}

export type LanguageChangerProps = LanguageChangerStateProps & LanguageChangerDispatchProps & WithT;


export interface LanguageOption extends DropdownOption {
    src: string;
}

export const languageOptions: LanguageOption[] = [
    {
        key: 'GB',
        text: 'login:footer.languageSelector.en-GB',
        value: 'en-GB',
        src: GB
    },
    {
        key: 'PL',
        text: 'login:footer.languageSelector.pl-PL',
        value: 'pl-PL',
        src: PL
    }
];
