import React, { useEffect } from 'react';

import { LoginStep } from 'models/app/workflow';

import { removeTokens } from 'utils/auth/token';
import { LoginProps } from './Login.types';

import LoginLayout from './LoginLayout/LoginLayout.layout';
import LoginAuthorisation from './loginSteps/LoginAuthorisation/LoginAuthorisation';
import LoginSuccess from './loginSteps/LoginSuccess/LoginSuccess';
import LoginTemporaryBlockade from './loginSteps/LoginTemporaryBlockade/LoginTemporaryBlockade';
import LoginBlockedUser from './loginSteps/LoginBlockedUser/LoginBlockedUser';

export const Login = ({ loginStep }: LoginProps) => {

    useEffect(() => {
        removeTokens();
    }, []);

    const renderLoginStep = (currentStep: LoginStep): JSX.Element => {
        const conditions: Record<LoginStep, JSX.Element> = {
            AUTHORISATION: <LoginAuthorisation />,
            SUCCESS: <LoginSuccess />,
            TEMPORARY_BLOCKADE: <LoginTemporaryBlockade />,
            BLOCKED_USER: <LoginBlockedUser />
        };

        return conditions[currentStep ?? LoginStep.AUTHORISATION];
    };


    return (
        <LoginLayout>
            { renderLoginStep(loginStep) }
        </LoginLayout>
    );
};
