import {
    CLEAR_SMS_CODE_FORM,
    ClearSmsCodeForm,
    SET_SMS_CODE_FORM_ERROR,
    SET_SMS_FIELD_VALUE,
    SetSmsCodeFormError,
    SetSmsFieldValue,
    SetSmsFieldValuePayload
} from './smsCodeForm.types';

export const setSmsFieldValue = (payload: SetSmsFieldValuePayload): SetSmsFieldValue => ({ type: SET_SMS_FIELD_VALUE, payload });
export const setSmsCodeFormError = (state: boolean): SetSmsCodeFormError => ({ type: SET_SMS_CODE_FORM_ERROR, payload: state });
export const clearSmsCodeForm = (): ClearSmsCodeForm => ({ type: CLEAR_SMS_CODE_FORM });
