import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Dispatch } from 'models/meta/action';
import { finishWorkflow } from 'store/actions/workflow';

import { PaymentFinalisedDispatchProps } from './PaymentFinalised.types';
import PaymentFinalised from './PaymentFinalised.layout';


const mapDispatchToProps = (dispatch: Dispatch): PaymentFinalisedDispatchProps =>
    ({ dispatchFinishWorkflow: () => dispatch(finishWorkflow()) });


export default connect(null, mapDispatchToProps)(withTranslation('result-screen')(PaymentFinalised));
