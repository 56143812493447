import React from 'react';

import Heading from 'components/reusable/authorization/Heading/Heading.layout';
import ConsentDetails from 'components/reusable/authorization/ConsentDetails/ConsentDetails.layout';
import SmsCodeForm from 'components/reusable/authorization/SmsCodeForm/SmsCodeForm';
import Tooltip from 'components/reusable/Tooltip/Tooltip.layout';
import Styled from 'components/reusable/Overalys/Overlays.styled';

import { ConsentVerificationProps } from './ConsentVerification.types';

export default function ConsentVerification(props: ConsentVerificationProps) {


    return (
        <main>
            <Heading
                pageHeader={props.t('pageHeader')}
                iconName="key"
                iconColor="accent.light"
                pageSubheader={props.t('step2pageSubheader')}
            />

            <SmsCodeForm />

            <Styled.DetailsOverlay>
                <ConsentDetails {...props} />
            </Styled.DetailsOverlay>
            <Tooltip />
        </main>
    );
}
