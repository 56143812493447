import styled from 'styled-components';
import { WithTheme } from 'config/theme';

const AttributeWrap = styled.div`
  width: 100%;
  text-align: center;
`;

const AttributeLabel = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }: WithTheme) => theme.colors.black};
`;

const AttributeName = styled.div`
  margin-bottom: 5px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  color: ${({ theme }: WithTheme) => theme.colors.accent.dark};
`;

const AttributeValue = styled.div`
  margin-bottom: 5px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  color: ${({ theme }: WithTheme) => theme.colors.accent.light};
`;

const AttributeHint = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }: WithTheme) => theme.colors.font.onPrimaryLighter};
`;

export default { AttributeWrap, AttributeLabel, AttributeName, AttributeValue, AttributeHint };
