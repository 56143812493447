import { ActionSource, ActionType } from 'models/domain/authorisation';
import { AuthorisationDetails } from 'models/domain/xs2a';

export enum AuthorisationType {
    LOGIN = 'LOGIN',
    PAYMENT = 'PAYMENT',
    CONSENT = 'CONSENT',
    UNKNOWN = 'UNKNOWN'
}

export function toAuthorisationType(authorisationDetails: AuthorisationDetails): AuthorisationType {
    if (authorisationDetails.consentDetails) return AuthorisationType.CONSENT;
    if (authorisationDetails.paymentDetails) return AuthorisationType.PAYMENT;
    return AuthorisationType.UNKNOWN;
}

const authorisationActionType: Record<AuthorisationType, ActionType | undefined> = {
    [AuthorisationType.LOGIN]: ActionType.LOGIN,
    [AuthorisationType.CONSENT]: ActionType.TPP_CONSENT,
    [AuthorisationType.PAYMENT]: ActionType.TPP_PAYMENT,
    [AuthorisationType.UNKNOWN]: undefined,
};

const authorisationActionSource: Record<AuthorisationType, ActionSource | undefined> = {
    [AuthorisationType.LOGIN]: ActionSource.APP,
    [AuthorisationType.CONSENT]: ActionSource.XS2A,
    [AuthorisationType.PAYMENT]: ActionSource.XS2A,
    [AuthorisationType.UNKNOWN]: undefined,
};

export function toActionType(authorisationType: AuthorisationType): ActionType {
    const actionType = authorisationActionType[authorisationType];
    if (!actionType) throw new Error(`Unknown authorisation type`);
    return actionType;
}

export function toActionSource(authorisationType: AuthorisationType): ActionSource {
    const actionSource = authorisationActionSource[authorisationType];
    if (!actionSource) throw new Error(`Unknown authorisation type`);
    return actionSource;
}

export enum WorkflowStep {
    INIT = 'INIT',
    BAD_REQUEST = 'BAD_REQUEST',
    LOGIN = 'LOGIN',

    AUTHORISATION = 'AUTHORISATION',
    VERIFICATION = 'VERIFICATION',
    FINALISED = 'FINALISED',
    DECLINED = 'DECLINED',
}

export enum LoginStep {
    AUTHORISATION = 'AUTHORISATION',
    SUCCESS = 'SUCCESS',
    BLOCKED_USER = 'BLOCKED_USER',
    TEMPORARY_BLOCKADE = 'TEMPORARY_BLOCKADE'
}
