import styled from 'styled-components';
import { NavigationBarInnerWrap, MainMenuButtonLinks, MainMenuIconLinks, NavigationBar } from 'assets/styles/global/layout.styled';

const NavigationBarDecoration = styled(NavigationBar)`
    height: 5px;
`;

const NavigationBarDecorationInnerWrap = styled(NavigationBarInnerWrap)`
    height: 5px;
`;

const NavigationBarDecorationLighter = styled(MainMenuButtonLinks)`
    margin-left: 80px;
`;

const NavigationBarDecorationLightest = styled(MainMenuIconLinks)`
    min-width: 380px;
`;

export default {
    NavigationBarDecoration,
    NavigationBarDecorationInnerWrap,
    NavigationBarDecorationLighter,
    NavigationBarDecorationLightest,
};
