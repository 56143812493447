import { AuthorisationDetails, Xs2aAuthorisationId } from 'models/domain/xs2a';

import {
    PREPARE_AUTHORISATION_DETAILS,
    PREPARE_AUTHORISATION_DETAILS_FAILURE,
    PREPARE_AUTHORISATION_DETAILS_SUCCESS,
    PrepareAuthorisationDetails,
    PrepareAuthorisationDetailsFailure,
    PrepareAuthorisationDetailsSuccess
} from './xs2a.types';


export const prepareAuthorisationDetails = (xs2aAuthorisationId: Xs2aAuthorisationId): PrepareAuthorisationDetails =>
    ({ type: PREPARE_AUTHORISATION_DETAILS, payload: xs2aAuthorisationId });

export const prepareAuthorisationDetailsSuccess = (details: AuthorisationDetails): PrepareAuthorisationDetailsSuccess =>
    ({ type: PREPARE_AUTHORISATION_DETAILS_SUCCESS, payload: details });

export const prepareAuthorisationDetailsFailure = (): PrepareAuthorisationDetailsFailure =>
    ({ type: PREPARE_AUTHORISATION_DETAILS_FAILURE });
