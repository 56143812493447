import { createGlobalStyle } from "styled-components";

import { layout } from './layout.styled';
import reset from './reset.styled';
import dropdown from './dropdown.styled';
import transitions from '../animations/transitions.styled';

export const GlobalStyles = createGlobalStyle`
    ${layout}
    ${dropdown}
    ${reset}
    ${transitions}
`;
