import React from 'react';

import Heading from 'components/reusable/authorization/Heading/Heading.layout';
import PaymentsDetails from 'components/reusable/authorization/PaymentDetails/PaymentDetails.layout';
import SmsCodeForm from 'components/reusable/authorization/SmsCodeForm/SmsCodeForm';
import Tooltip from 'components/reusable/Tooltip/Tooltip.layout';
import Styled from 'components/reusable/Overalys/Overlays.styled';

import { PaymentVerificationProps } from './PaymentVerification.types';

export default function PaymentVerification(props: PaymentVerificationProps) {
    const { t } = props;

    return (
        <main>
            <Heading
                pageHeader={t('pageHeader')}
                iconName="key"
                iconColor="accent.light"
                pageSubheader={t('step2pageSubheader')}
            />

            <SmsCodeForm />
            <Styled.DetailsOverlay>
                <PaymentsDetails {...props} />
            </Styled.DetailsOverlay>
            <Tooltip />
        </main>
    );
}
