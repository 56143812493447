import styled from 'styled-components';

import { WithTheme } from 'config/theme';
import { IconFont } from 'assets/styles/icons/Icons.styled';

const HeadingWrap = styled.section`
  text-align: center;
`;

const HeadingTitle = styled.h1`
  margin-bottom: 30px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  color: ${({ theme }: WithTheme) => theme.colors.black};
`;

const HeadingIcon = styled(IconFont)`
  margin-bottom: 20px;
  font-size: 60px;
`;

const HeadingDescription = styled.h2`
  margin-bottom: 27px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: ${({ theme }: WithTheme) => theme.colors.font.onPrimaryLighter};
`;

export default { HeadingWrap, HeadingTitle, HeadingIcon, HeadingDescription };
