import { Action } from 'models/meta/action';
import { User, AuthTokens, SignInCredentials } from 'models/domain/user';
import { ClearStore } from './common.types';

type SIGN_ON = 'SIGN_ON';
type SIGN_ON_SUCCESS = 'SIGN_ON_SUCCESS';
type SIGN_ON_FAILURE = 'SIGN_ON_FAILURE';
type SIGN_OFF = 'SIGN_OFF';
type REFRESH_TOKEN = 'REFRESH_TOKEN';
type REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
type REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const SIGN_ON: SIGN_ON = 'SIGN_ON';
export const SIGN_ON_SUCCESS: SIGN_ON_SUCCESS = 'SIGN_ON_SUCCESS';
export const SIGN_ON_FAILURE: SIGN_ON_FAILURE = 'SIGN_ON_FAILURE';
export const SIGN_OFF: SIGN_OFF = 'SIGN_OFF';
export const REFRESH_TOKEN: REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS: REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE: REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

interface SignOnPayload {
    credentials: SignInCredentials;
}

export interface SignOn extends Action {
    type: SIGN_ON;
    payload: SignOnPayload;
}

export interface SignOnSuccess extends Action {
    type: SIGN_ON_SUCCESS;
    payload: User;
}

export interface SignOnFailure extends Action {
    type: SIGN_ON_FAILURE;
}

export interface SignOff extends Action {
    type: SIGN_OFF;
}

export interface RefreshToken extends Action {
    type: REFRESH_TOKEN;
}

export interface RefreshTokenSuccess extends Action {
    type: REFRESH_TOKEN_SUCCESS;
    payload: AuthTokens;
}

export interface RefreshTokenFailure extends Action {
    type: REFRESH_TOKEN_FAILURE;
    payload: { unauthorized: boolean };
}

type SignActions = SignOn | SignOnSuccess | SignOnFailure | SignOff;
type TokenActions = RefreshToken | RefreshTokenSuccess | RefreshTokenFailure;

export type UserActions = SignActions | TokenActions | ClearStore;
