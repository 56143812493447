import styled, { css, ThemeProps } from 'styled-components';

import { Theme } from 'config/theme';
import { IconFont } from 'assets/styles/icons/Icons.styled';
import { ColorTransition } from 'assets/styles/animations/animations.styled';
import { ErrorProp, IconProp, FieldIconStyleProps } from './FormField.types';

const getErrorBorderColor = ({ theme }: ThemeProps<Theme>) => ({ borderColor: theme.colors.error.base });

const getErrorColor = ({ theme }: ThemeProps<Theme>) => ({ color: theme.colors.error.base });

const getLabelFocusColor = ({ theme }: ThemeProps<Theme>) => ({ color: theme.colors.accent.light });

const getFocusBorderColor = ({ theme }: ThemeProps<Theme>) => ({ borderColor: theme.colors.accent.light });

const getInputAndIconColor = ({ theme }: ThemeProps<Theme>) => ({ color: theme.colors.black });

export const FormFieldWithError = styled.div`
    width: 100%;
    padding: 8px 0 25px;
    position: relative;
`;

export const AnimatedLabelWrap = styled.div<ErrorProp & IconProp>`
    position: relative;
    width: 100%;
    
    input {
      ${props => props.hasIcon && css`padding-right: 60px;`};
    }

    input:hover + .input-label,
    input:focus + .input-label,
    i:hover ~ .input-label {
      ${props => (props.hasError ? getErrorColor(props) : getLabelFocusColor(props))};
    }
    
    i:hover + input  {
      ${props => (props.hasError ? getErrorBorderColor(props) : getFocusBorderColor(props))};
    }
`;

const FieldIcon = styled(IconFont)<FieldIconStyleProps>`
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 21px;
    font-size: 18px;
    transform: translateY(-50%);
    ${props => getInputAndIconColor(props)}
    ${({ hasError, theme }) => hasError && css`color: ${theme.colors.error.base}`};
    ${({ isValid, theme }) => isValid && css`color: ${theme.colors.accent.light}`};
    ${ColorTransition};
`;

export default { AnimatedLabelWrap, FormFieldWithError, FieldIcon };
