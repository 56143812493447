/* no destructing because whole ‘process.env’ text is substituted by DefinePlugin during build */
const env = process.env; // eslint-disable-line prefer-destructuring

export const developmentMode = env.NODE_ENV === 'development';
export const debugMode = developmentMode && false;
export const apiURL = env.API_URL;
export const openBankingURL = env.OPENBANKING_URL;

export const defaultRoutePath = '/';
export const defaultTppRedirectUrl = 'https://okeo.com/';

const TOKEN_DEFAULTS = {
    /** Expiration time of the token in seconds; this is vendor-specific */
    OKEO_TOKEN_EXPIRATION_TIME: 60 * 60,
    /** Refresh timeout of the token in seconds after which user will be signed off due to inactivity; on production should be equal to the life-time */
    OKEO_TOKEN_REFRESH_TIMEOUT: 60,
    /** Delay in seconds after which first refresh attempt based on activity will be made (should be lower than the timeout) */
    OKEO_TOKEN_ACTIVITY_DELAY: 45,
};

const numberFromEnv = (key: keyof typeof TOKEN_DEFAULTS, postprocess: (_: number) => number = x => x) => postprocess(parseInt(env[key] || '', 10) || TOKEN_DEFAULTS[key]);
const toMillis = (seconds: number) => seconds * 1000;

/** Expiration time (in ms) of the token */
export const tokenExpirationTime = numberFromEnv('OKEO_TOKEN_EXPIRATION_TIME', toMillis);

/** Timeout (in ms) after which user will be signed off due to inactivity; on production should be equal to the life-time */
export const tokenRefreshTimeout = numberFromEnv('OKEO_TOKEN_REFRESH_TIMEOUT', toMillis);

/** Delay (in ms) after which first refresh attempt based on activity will be made (should be lower than the timeout) */
export const tokenActivityDelay = numberFromEnv('OKEO_TOKEN_ACTIVITY_DELAY', toMillis);
