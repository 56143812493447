import { Action } from 'models/meta/action';

export const SHOW_SUCCESS_TOAST = 'SHOW_SUCCESS_TOAST';
export const SHOW_INFO_TOAST = 'SHOW_INFO_TOAST';
export const SHOW_WARN_TOAST = 'SHOW_WARN_TOAST';
export const SHOW_ERROR_TOAST = 'SHOW_ERROR_TOAST';

export const CLEAR_STORE = 'CLEAR_STORE';

export const NAVIGATE = 'NAVIGATE';

export interface ClearStore extends Action {
    type: typeof CLEAR_STORE;
}


export interface Navigate extends Action {
    type: typeof NAVIGATE;
    payload: string;
}
