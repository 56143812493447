import styled, { css } from 'styled-components';
import Select from 'react-dropdown-select';
import {
    color, ColorProps, display, DisplayProps, flexbox, FlexboxProps, height, HeightProps, space, SpaceProps
} from 'styled-system';
import { ScrollBar } from 'assets/styles/mixins/scrollbar';

export const DropdownContainer = styled.div<SpaceProps & { fixedWidth?: string }>`
    background: #fff;
    width: ${({ fixedWidth }) => fixedWidth || '100%'};
    line-height: 1;
    position: relative;
    ${space}
`;
export const DropdownList = styled.ul<{ open?: boolean }>`
    display: none;
    position: absolute;
    z-index: 10;
    margin: 0;
    padding: 0;
    background: #fff;
    max-height: calc(5 * 55px);
    overflow-y: auto;
    ${ScrollBar}

    ${({ open }) =>
        open
        && css`
            display: block;
        `}
`;

export const DropdownSelectedItem = styled.div<{
    open: boolean;
}>`
    width: 100%;
    min-width: 150px;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
        font-size: 16px;
        transition: transform 0.2s;
        margin-left: 8px;
    }

    div:hover > &,
    div.hover > & {
        border-color: ${({ theme }) => theme.colors.accent.light};
    }

    ${({ open }) =>
        open
        && css`
            i {
                transform: rotate(180deg);
            }
        `}
`;

export const DropdownListItem = styled.li<{ active?: boolean } & DisplayProps & FlexboxProps & SpaceProps>`
  border: 1px solid ${({ theme }) => theme.colors.gray.base};
  border-top: none;
  padding: 18px;
  text-align: left;
  color: initial;
  ${flexbox}
  ${display}
  ${space}
  
  &:hover {
    background: ${({ theme }) => theme.colors.gray.xlight};
  }
  
  ${({ active }) =>
        active
            ? css`
                background: ${({ theme }) => theme.colors.gray.xlight};
            `
            : ''}
`;

export const SelectedValueWrapper = styled.div`
    min-height: 16px;
    line-height: 1;
`;

export const SelectedValue = styled.span<HeightProps & ColorProps & { bolder?: boolean }>`
    font-weight: ${({ bolder, theme }) => bolder ? theme.fontWeights.bold : 'initial'};
    ${height}
    ${color}
`;

export const StyledSelect = styled(Select)<{ noBorder?: boolean }>`
&.react-dropdown-select {
    position: relative;
    display: flex;
    width: 100%;
    border-radius: 0px;
    padding: 0; 
    align-items: center;
    cursor: pointer;
    min-height: 36px;
    min-width: 150px;
    justify-content: space-between;
    border: none;

    :hover,
    :focus,
    :focus-within {
        outline: 0;
        border-color: transparent;
        box-shadow: none;
    }
   
    
    .react-dropdown-select-content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 18px 0;
        white-space: nowrap;
        line-height: 1.15;

        & > span {
            ${height}
            ${color}
        }
    
        div:hover > &,
        div.hover > & {
          border-color: ${({ theme }) => theme.colors.accent.light};
        }
    
        .react-dropdown-select-input {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 18px 0;
            font-size: 14px;
            white-space: nowrap;
            text-overflow: ellipsis;
            background: transparent;
            
            ::placeholder {
              color: ${({ theme }) => theme.colors.gray.dark};
            }
        }
    }

    .react-dropdown-select-dropdown-handle.react-dropdown-select-dropdown-handle {
        margin: 0 10px;
        color: #000;
        transition: transform 0.2s ease 0s;
    
        svg {
            height: 20px;
            width: 20px;
        }
    }
   
   .react-dropdown-select-dropdown {
        margin-left: 0;
        border-top: 1px solid ${({ theme }) => theme.colors.gray.base};
    }
   
}
`;

DropdownContainer.displayName = 'DropdownContainer';
DropdownList.displayName = 'DropdownList';
DropdownSelectedItem.displayName = 'DropdownSelectedItem';
DropdownListItem.displayName = 'DropdownListItem';
