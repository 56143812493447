import { Action } from 'models/meta/action';
import { AuthorisationDetails, Xs2aAuthorisationId } from 'models/domain/xs2a';


type PREPARE_AUTHORISATION_DETAILS = 'PREPARE_AUTHORISATION_DETAILS';
type PREPARE_AUTHORISATION_DETAILS_SUCCESS = 'PREPARE_AUTHORISATION_DETAILS_SUCCESS';
type PREPARE_AUTHORISATION_DETAILS_FAILURE = 'PREPARE_AUTHORISATION_DETAILS_FAILURE';

export const PREPARE_AUTHORISATION_DETAILS = 'PREPARE_AUTHORISATION_DETAILS';
export const PREPARE_AUTHORISATION_DETAILS_SUCCESS = 'PREPARE_AUTHORISATION_DETAILS_SUCCESS';
export const PREPARE_AUTHORISATION_DETAILS_FAILURE = 'PREPARE_AUTHORISATION_DETAILS_FAILURE';


export interface PrepareAuthorisationDetails extends Action {
    type: PREPARE_AUTHORISATION_DETAILS;
    payload: Xs2aAuthorisationId;
}

export interface PrepareAuthorisationDetailsSuccess extends Action {
    type: PREPARE_AUTHORISATION_DETAILS_SUCCESS;
    payload: AuthorisationDetails;
}

export interface PrepareAuthorisationDetailsFailure extends Action {
    type: PREPARE_AUTHORISATION_DETAILS_FAILURE;
}


export type Xs2aActions = PrepareAuthorisationDetails | PrepareAuthorisationDetailsSuccess | PrepareAuthorisationDetailsFailure;
