import React from 'react';

import { DropdownOption } from 'models/app/common';
import { DropdownProps } from './Dropdown.types';
import { StyledSelect } from './Dropdown.styled';

function createFindInputMatchingOption<T>(getValueKey: DropdownProps<T>['getValueKey'], inputValue: T) {
    return getValueKey !== undefined
        ? (option: DropdownOption) => option.key === getValueKey(inputValue)
        : (option: DropdownOption) => option.value === inputValue;
}

export function Dropdown<T, K>(props: DropdownProps<T, K>) {

    const { getValueKey, allowReselect } = props;
    const inputValue = props.input?.value;

    const inputMatchingOption = createFindInputMatchingOption<T | undefined>(getValueKey, inputValue);

    const inputOption = props.options.find(inputMatchingOption);
    const selected: DropdownOption[] = inputOption ? [inputOption] : [];

    const onSelect = (options: DropdownOption[]) => {
        const option = options[0];

        if (option?.value && (allowReselect || option.key !== inputOption?.key)) {

            props.onChange?.(option.value);
            props.input?.onChange?.(option.value);
        }
    };

    return (
        <div
            className="dropdownSelectOuterWrap"
            data-test-id={`${props.input?.name}-dropdown`}
        >
            <StyledSelect
                searchable={false}
                options={props.options}
                values={selected}
                dropdownGap={0}
                disabled={props.disabled}
                closeOnSelect={props.closeOnSelect}
                onDropdownOpen={props.onDropdownOpen}
                onChange={(values: DropdownOption<T, K>[]) => onSelect(values)}
                onDropdownClose={props.onDropdownClose}
                contentRenderer={props.contentRenderer ? props.contentRenderer : undefined}
                itemRenderer={props.itemRenderer ? props.itemRenderer : undefined}
                noDataLabel={props.noDataLabel ? props.noDataLabel : undefined}
                placeholder={props.placeholder ? props.placeholder : ''}
                dropdownPosition={props.dropdownPosition}
                sortBy="key"
                labelField="text"
                valueField="key"
                dropdownHeight="200px"
            />
        </div>
    );
}
