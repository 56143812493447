import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Dispatch } from 'models/meta/action';
import { State } from 'models/app/store';
import { WorkflowStep } from 'models/app/workflow';
import { startAuthorisation } from 'store/actions/authorisation';
import { setWorkflowStep } from 'store/actions/workflow';

import { ConsentAuthorisationDispatchProps, ConsentAuthorisationStateProps } from './ConsentAuthorisation.types';
import ConsentAuthorisation from './ConsentAuthorisation.layout';


const mapStateToProps = (state: State): ConsentAuthorisationStateProps => ({
    tppName: state.xs2a.authorisation.tppName,
    consentDetails: state.xs2a.authorisation.consentDetails!,
});

const mapDispatchToProps = (dispatch: Dispatch): ConsentAuthorisationDispatchProps => ({
    dispatchDeclineAuthorisation: () => dispatch(setWorkflowStep(WorkflowStep.DECLINED)),
    dispatchStartAuthorisation: () => dispatch(startAuthorisation())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('ais-consent')(ConsentAuthorisation));
