import React from 'react';

import { AttributeProps } from './Attribute.types';
import Styled from './Attribute.styled';

export default function Attribute({
    label,
    value,
    name,
    hint,
    tooltip,
}: AttributeProps) {
    return (
        <Styled.AttributeWrap>
            <Styled.AttributeLabel>{label}</Styled.AttributeLabel>
            {name && <Styled.AttributeName>{name}</Styled.AttributeName>}
            <Styled.AttributeValue data-tip={tooltip}>{value}</Styled.AttributeValue>
            {hint && <Styled.AttributeHint>{hint}</Styled.AttributeHint>}
        </Styled.AttributeWrap>
    );
}
