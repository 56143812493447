import React from 'react';
import { formatDateTime } from 'utils/text-formatters';

export default function AppVersionLayout() {
    return (
        <div>
            <div>
                v:&nbsp;
                {process.env.APP_VERSION}
            </div>
            <div>
                Build date:&nbsp;
                {formatDateTime(process.env.APP_BUILD_DATE || '?')}
            </div>
        </div>
    );
}
