import React, { FunctionComponent } from "react";

import SpinnerPaletteRing from 'components/reusable/Spinners/SpinnerPaletteRing';
import Styled from 'components/reusable/Overalys/Overlays.styled';


const AppLoadingOverlay: FunctionComponent = () => (
    <Styled.AppLoadingPlaceholder>
        <Styled.Center>
            <SpinnerPaletteRing />
        </Styled.Center>
    </Styled.AppLoadingPlaceholder>
);

export default AppLoadingOverlay;
