import { applyMiddleware, compose, createStore, Store } from 'redux';
import { History } from 'history';
import { createEpicMiddleware } from 'redux-observable';

import 'config/i18next';
import 'config/sentry';

import { HttpService } from 'services/HttpService';
import AuthorisationService from 'services/AuthorisationService';
import Xs2aService from 'services/xs2aService';
import { developmentMode } from 'config/config';
import { Action } from 'models/meta/action';
import { State } from 'models/app/store';

import { rootReducer } from './reducers';
import rootEpic from './epics';


export interface Dependencies {
    history: History,
    apiHttp: HttpService,
    xs2aHttp: HttpService,
    authorisation: typeof AuthorisationService,
    xs2a: typeof Xs2aService
}

export function configureStore(dependencies: Dependencies): Store<State> {
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const epicMiddleware = createEpicMiddleware<Action, Action, State, Dependencies>({ dependencies });
    const middleware = applyMiddleware(epicMiddleware);
    const enhancer = developmentMode ? composeEnhancers(middleware) : middleware;

    const storeInstance = createStore(rootReducer, enhancer);

    epicMiddleware.run(rootEpic);

    return storeInstance;
}
