import React from 'react';

import Styled from './AppToast/AppToast.styled';

export default function renderToastMessage(title: string, message: string): JSX.Element {
    return (
        <div>
            <Styled.AppToastTitle color="gray.xdark" fontSize={1} mb={2}>
                {title}
            </Styled.AppToastTitle>
            <Styled.AppToastText color="gray.xdark">{message}</Styled.AppToastText>
        </div>
    );
}
