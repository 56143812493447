import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import { Normalize } from 'styled-normalize';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import { createBrowserHistory } from 'history';

import theme from 'config/theme';
import { developmentMode } from 'config/config';
import { configureStore, Dependencies } from 'store';
import { ApiHttpService, Xs2aHttpService, createApiHttpService, createXs2aHttpService } from 'services/HttpService';

import Xs2aService from 'services/xs2aService';
import AuthorisationService from 'services/AuthorisationService';
import AppToastContainer from 'components/reusable/Toast/AppToastContainer/AppToastContainer.layout';
import App from 'components/App/App';
import { GlobalStyles } from 'assets/styles/global/global.styled';
import { GlobalFonts } from 'assets/styles/global/fonts.styled';


const wrapper = document.getElementById('app') as HTMLDivElement; // must be present
const history = createBrowserHistory();
const apiHttp = createApiHttpService();
const xs2aHttp = createXs2aHttpService();

const dependencies: Dependencies = {
    history,
    apiHttp,
    xs2aHttp,
    authorisation: AuthorisationService,
    xs2a: Xs2aService,
};

const store = configureStore(dependencies);

ApiHttpService.configure(store.dispatch);
Xs2aHttpService.configure(store.dispatch);

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <Router history={history}>
                <Normalize />

                <Switch>
                    <Route path="/:token([0-9a-f]{32})?" exact strict><App /></Route>
                    <Route><App /></Route>
                </Switch>

                <AppToastContainer />
            </Router>
        </ThemeProvider>
        <GlobalStyles />
        <GlobalFonts />
    </Provider>,
    wrapper
);

if (developmentMode && module.hot) {
    /* eslint-disable no-console */
    module.hot.accept(error => console.error(error));
    module.hot.addStatusHandler(status => console.log(`HMR: ${status}`));
    /* eslint-enable no-console */
}
