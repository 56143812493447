import styled from 'styled-components';
import {
    BorderColorProps,
    ColorProps,
    FontSizeProps,
    FontWeightProps,
    SpaceProps,
    LineHeightProps,
    borderColor,
    color,
    fontSize,
    fontWeight,
    lineHeight,
    space
} from 'styled-system';

const ToastMessageWrapper = styled.div<BorderColorProps>`
    padding: 16px;
    border-left: 5px solid;
    display: flex;
    align-items: center;
    ${borderColor}
`;

export const AppToastTitle = styled.h1<SpaceProps & FontSizeProps & FontWeightProps & ColorProps>`
    margin: 0;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    ${space};
    ${color};
    ${fontSize};
    ${fontWeight};
`;

export const AppToastText = styled.p<SpaceProps & ColorProps & FontSizeProps & LineHeightProps>`
    line-height: 1;
    margin: 0;
    ${color};
    ${space};
    ${fontSize};
    ${lineHeight}
`;

export default { ToastMessageWrapper, AppToastTitle, AppToastText };
