import { ofType } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, concatMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { toAuthorisationType, WorkflowStep } from 'models/app/workflow';
import { Epic } from 'models/meta/epic';
import { PREPARE_AUTHORISATION_DETAILS, PREPARE_AUTHORISATION_DETAILS_SUCCESS } from 'store/actions/xs2a.types';
import { setWorkflowStep, startWorkflow } from 'store/actions/workflow';

import { prepareAuthorisationDetailsFailure, prepareAuthorisationDetailsSuccess } from 'store/actions/xs2a';

export const onPrepareAuthorisationDetails: Epic = (
    action$,
    state$,
    { xs2a: xs2aService }
) =>
    action$.pipe(
        ofType(PREPARE_AUTHORISATION_DETAILS),
        switchMap(({ payload: xs2aAuthorisationId }) => from(xs2aService.prepareAuthorisation(xs2aAuthorisationId)).pipe(
            concatMap((authorisationDetails) => {
                return of(
                    prepareAuthorisationDetailsSuccess(authorisationDetails),
                    startWorkflow(toAuthorisationType(authorisationDetails)),
                    setWorkflowStep(WorkflowStep.AUTHORISATION)
                );
            }),
            catchError(() => of(prepareAuthorisationDetailsFailure(), setWorkflowStep(WorkflowStep.DECLINED)))
        ))
    );


export const onPrepareAuthorisationDetailSuccess: Epic = (
    action$,
    state$,
    { apiHttp, xs2aHttp }
) =>
    action$.pipe(
        ofType(PREPARE_AUTHORISATION_DETAILS_SUCCESS),
        withLatestFrom(state$),
        tap(([, storeState]) => {
            apiHttp.setProfileId(storeState.xs2a.authorisation.profileId);
            xs2aHttp.setProfileId(storeState.xs2a.authorisation.profileId);
        }),
        switchMap(() => EMPTY)
    );
