import React, { useEffect } from 'react';

import Icons from 'assets/styles/icons/icons';
import { DecorationIcon } from 'components/pages/Login/LoginStep/LoginStep.styled';

import LoginStep from 'components/pages/Login/LoginStep/LoginStep';
import { LoginSuccessProps } from './LoginSuccess.types';
import Styled from './LoginSuccess.styled';


export const LoginSuccess = ({ t, dispatchPrepareAuthorisationDetails, xs2aAuthorisationId }: LoginSuccessProps) => {

    useEffect(() => {
        setTimeout(() => {
            dispatchPrepareAuthorisationDetails(xs2aAuthorisationId);
        }, 2000);
    }, []);

    return (
        <LoginStep pageHeader={t('pageHeader.loginToApp')}>
            <Styled.SuccessSubHeader>{t('successWindow.header')}</Styled.SuccessSubHeader>

            <DecorationIcon
                icon={Icons.checkMarkCircle}
                color="accent.light"
            />

            <Styled.InfoMessage>{t('successWindow.text')}</Styled.InfoMessage>
        </LoginStep>
    );
};
