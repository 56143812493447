import styled from 'styled-components';
import { SpaceProps } from 'styled-system';

const LanguageChangerWrap = styled.div<SpaceProps>`
    position: absolute;
    right: 0;
    display: flex;
    align-content: center;
    max-height: 60px;
    
    .react-dropdown-select-content.react-dropdown-select-content {
      padding: 16px 10px;
    }
`;

const FlagIcon = styled.img`
    height: 32px;
    width: 32px;
    margin: 0 0 0 14px;
    transform: scale(0.6);
    transition: all 0.3s ease-in-out 0s;
`;


export default {
    LanguageChangerWrap,
    FlagIcon,
};
