import styled from 'styled-components';

import { WithTheme } from 'config/theme';
import { IconFont } from 'assets/styles/icons/Icons.styled';


const AccountItemWrap = styled.div`
   display: flex;
   margin-bottom: 23px;
   box-shadow: 0 3px 6px rgba(0, 0, 0, .1);
`;

const AccountNameAndIBAN = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  padding: 30px 40px;
  color: ${({ theme }: WithTheme) => theme.colors.black};
`;

const AccountName = styled.div`
  margin-bottom: 7px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
`;

const AccountIBAN = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 22px;
  
`;

const AccountAccessScope = styled.div`
  width: 180px;
  position: relative;
  padding: 20px 0 24px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: ${({ theme }: WithTheme) => theme.colors.gray.dark};

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: ${({ theme }: WithTheme) => theme.colors.accent.light};
    }
`;

const AccountAccessScopeIconsWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AccountAccessScopeLabel = styled.div`
  margin-bottom: 18px;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
`;

const AccessIcon = styled(IconFont)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  margin: 0 4px;
  font-size: 17px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme }: WithTheme) => theme.colors.accent.light};
  border-radius: 100%;
  cursor: help;
`;

export default { AccountItemWrap, AccountNameAndIBAN, AccountName, AccountIBAN, AccountAccessScope, AccountAccessScopeLabel, AccountAccessScopeIconsWrap, AccessIcon };
