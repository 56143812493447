import styled from 'styled-components';

const AppLoadingPlaceholder = styled.div`
       position: fixed;
       top: 0;
       bottom: 0;
       left: 0;
       right: 0;
       z-index: 1000;
       background-color: rgba(255, 255, 255, 0.9);
       animation-delay: unset;
`;

const SectionLoadingWrapper = styled.div`
       position: relative;
       width: 100%;
`;

const SectionLoadingPlaceholder = styled.div`
       position: absolute;
       top: 0;
       bottom: 0;
       left: 0;
       right: 0;
       z-index: 80;
       min-height: 200px !important;
       background-color: rgba(255, 255, 255, 0.8);
       animation-delay: unset;
`;

const Center = styled.div`
       position: absolute;
       top: 50%;
       left: 50%;
       z-index: 100;
       transform: translate(-50%, -50%);
`;

export const DetailsOverlay = styled.div`
    position: relative;
    
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(255,255,255, .5);
      pointer-events: none;
    }

`;


export default { Center, AppLoadingPlaceholder, SectionLoadingPlaceholder, SectionLoadingWrapper, DetailsOverlay };
