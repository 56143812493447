import { AppState } from 'models/app/store';
import { AppActions, END_APP_LOADING, START_APP_LOADING } from 'store/actions/app.types';

import { AuthorisationActions } from 'store/actions/authorisation.types';

import { CLEAR_STORE } from 'store/actions/common.types';
import { createReducer, Handlers } from './reducer';

export const initialState: AppState = { isAppLoading: false };

const handlers: Handlers<AppState, AppActions & AuthorisationActions> = {
    [START_APP_LOADING]: () => {
        return { isAppLoading: true };
    },

    [END_APP_LOADING]: () => {
        return { isAppLoading: false };
    },

    [CLEAR_STORE]: () => {
        return initialState;
    }
};

export default createReducer(initialState, handlers);
