import { ClearStore } from './common.types';

type SET_LOGIN_LANGUAGE = 'SET_LOGIN_LANGUAGE';
type SET_LOGIN_STEP = 'SET_LOGIN_STEP';

export const SET_LOGIN_LANGUAGE: SET_LOGIN_LANGUAGE = 'SET_LOGIN_LANGUAGE';
export const SET_LOGIN_STEP: SET_LOGIN_STEP = 'SET_LOGIN_STEP';

export interface SetLoginLanguage {
    type: SET_LOGIN_LANGUAGE;
    payload: string;
}

export interface SetLoginStep {
    type: SET_LOGIN_STEP;
    payload: string;
}


export type LoginActions = SetLoginLanguage | SetLoginStep | ClearStore;
