import styled from 'styled-components';
import { MainContentWrap } from 'assets/styles/global/layout.styled';

const AuthorisedMainContentWrap = styled(MainContentWrap)`
    min-height: 100vh;
`;

const AuthorisedMainContent = styled.div`
    width: 650px;
    margin: 0 auto;
`;

export default { AuthorisedMainContentWrap, AuthorisedMainContent };
