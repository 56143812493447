import styled, { css } from 'styled-components';
import theme, { WithTheme } from 'config/theme';

const minContentWidth = '1024px';
const maxContentWidth = '1314px';
const minPadding = '10px';
const navBarHeight = 70;
const footerHeight = 72;
const scrollBarTrickPositionLeft = 'calc((100vw - 100%) * -1)';
const scrollBarTrick = 'calc(100vw - 100%)';

export const layout = css`
html {
    /* scroll bar trick -> https://aykevl.nl/2014/09/fix-jumping-scrollbar */
    margin-left: ${scrollBarTrick};
    margin-right: 0;
    scroll-behavior: smooth;

    /* disable the trick */
    @media screen and (max-width: ${minContentWidth}) {
        margin-left: 0;
    }
}

body {
    margin: 0;
    min-width: ${minContentWidth};
    overflow: auto;
    width: 100%;
    background: ${theme.colors.white};
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;


   &.no-scroll {
      position: fixed; 
      overflow-y: scroll; 
   }
}

.appOuterWrapper {
    width: 100%;
    min-height: 100vh;
    position: relative;
}
`;

export const NavigationBar = styled.div`
    position: fixed; /* (sic!)  */
    top: 0;
    max-width: 100vw;
    left: 0;
    right: 0;
    height: ${navBarHeight}px;
    background:  ${theme.colors.gray.xlight};
    box-shadow: 0 3px 6px #0000001A;
    z-index: 100;
    
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 5px;
        background: ${({ theme }: WithTheme) => theme.colors.accent.dark};
    }
`;


export const NavigationBarOuterWrap = styled.div`
    position: relative;
    /* scroll bar trick compensation for centered content inside fixed parent */
    left: ${scrollBarTrickPositionLeft};
    padding-left: ${scrollBarTrick};
    background: #fff;
`;

export const NavigationBarInnerWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-shrink: 2;
    width: 100%;
    min-width: ${minContentWidth};
    max-width: ${maxContentWidth};
    height: ${navBarHeight}px;
    padding: 0 ${minPadding};
    margin: 0 auto;
    color: ${theme.colors.gray.xdark};
    
    img {
      margin-top: 20px
    }
`;

export const MainMenuButtonLinks = styled.div`
    margin-left: 20px;
    padding-right: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    width: auto;
  
    
    &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        height: 5px;
        background: #659F39;
    }
`;

export const MainMenuIconLinks = styled.div`
      position: relative;
      display: flex;
      justify-content: flex-end;
      min-width: 200px;
      align-items: center;

      &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        height: 5px;
        background: ${({ theme }: WithTheme) => theme.colors.accent.light};
    }
`;

export const MainContentWrap = styled.main`
    /* standard main centered content  */
    max-width: ${maxContentWidth};
    margin: 0 auto;
    padding: ${navBarHeight + 20}px 0 ${footerHeight + 50}px;
`;

export const FooterOuterWrap = styled.div`
    position: absolute;
    left: ${scrollBarTrickPositionLeft}; /* scroll bar trick compensation for absolute footer */
    padding-left: ${scrollBarTrick};
    bottom: 0;
    height: ${footerHeight}px;
    width: 100vw;
    min-width: ${minContentWidth};
    box-shadow: 8px 0 6px rgba(0,0, 0, 0.1);
    background-color: ${theme.colors.gray.xlight};
    color: #8A8A8A;

   @media screen and (max-width: ${minContentWidth}) {
        left: 0;
        padding-left: 0;
   }
`;

export const FooterInnerWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: ${maxContentWidth};
    height: ${footerHeight}px;
    padding: 0 ${minPadding};
    color: ${theme.colors.gray.xdark};
    margin: 0 auto;
`;

export default {
    layout,
    NavigationBar,
    NavigationBarOuterWrap,
    NavigationBarInnerWrap,
    MainContentWrap,
    FooterOuterWrap,
    FooterInnerWrap,
    MainMenuButtonLinks,
    MainMenuIconLinks,
};
