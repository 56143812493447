import { Action } from 'models/meta/action';
import { State } from 'models/app/store';


type Reducer<S = State, A extends Action = Action> = (state: S | undefined, action: A) => S;
type Handler<S = State, P = any, M = any> = (payload: P, state: S, meta: M) => Partial<S>;
type Handlers<S, A extends Action = Action> = { [T in A['type']]?: A extends Action<infer P, T, infer M> ? Handler<S, P, M> : never; }

// eslint-disable-next-line max-len
function createReducer<S, K extends string = string, A extends Action<any, K> = Action<any, K>>(initialState: S, handlers: Handlers<S, A>, stateOverride: Partial<S> = {}): Reducer<S, A> {
    return (state = initialState, action) => {
        const handler = handlers[action.type];

        return handler ? { ...state, ...stateOverride, ...handler(action.payload, state, action.meta) } : state;
    };
}

export { Handlers, createReducer };
