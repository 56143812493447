import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { State } from 'models/app/store';
import { Dispatch } from 'models/meta/action';
import { SmsCode } from 'models/domain/authorisation';
import { SetSmsFieldValuePayload } from 'store/actions/smsCodeForm.types';
import { setSmsCodeFormError, setSmsFieldValue, clearSmsCodeForm } from 'store/actions/smsCodeForm';

import createWithLoading from 'hocs/loading/createWithLoading';
import LoadingOverlaySection from 'components/reusable/Overalys/SectionLoadingOverlay.layout';

import { cancelAuthorisation, resendCode, verifyCode } from 'store/actions/authorisation';
import SmsCodeForm from './SmsCodeForm.layout';
import { SmsCodeFormDispatchProps, SmsCodeFormStateProps } from './SmsCodeForm.types';

const loadingSelector = (state: State) => state.sms.isLoading;

const mapStateToProps = (state: State): SmsCodeFormStateProps => ({
    digit1Value: state.sms.digit1Value,
    digit2Value: state.sms.digit2Value,
    digit3Value: state.sms.digit3Value,
    digit4Value: state.sms.digit4Value,
    digit5Value: state.sms.digit5Value,
    digit6Value: state.sms.digit6Value,
    isLoading: state.sms.isLoading,
    hasError: state.sms.hasError,
    currentDayAuthorisationsCounter: state.authorisation.currentDayAuthorisationsCounter
});

const mapDispatchToProps = (dispatch: Dispatch): SmsCodeFormDispatchProps => ({
    dispatchSetSmsFieldValue: (payload: SetSmsFieldValuePayload) => dispatch(setSmsFieldValue(payload)),
    dispatchResendSmsCode: () => dispatch(resendCode()),
    dispatchVerifyCode: (code: SmsCode) => dispatch(verifyCode(code)),
    dispatchCancelAuthorisation: () => dispatch(cancelAuthorisation()),
    dispatchSetSmsCodeFormError: (hasError: boolean) => dispatch((setSmsCodeFormError(hasError))),
    dispatchClearSmsCodeForm: () => dispatch((clearSmsCodeForm()))
});


const decorate = compose(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps)
);

export default decorate(createWithLoading(SmsCodeForm, { loadingSelector, OverlayComponent: LoadingOverlaySection, positionLock: true }));
