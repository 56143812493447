import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { State } from 'models/app/store';
import { Dispatch } from 'models/meta/action';
import { Xs2aAuthorisationId } from 'models/domain/xs2a';
import { initWorkflow, finishWorkflow } from 'store/actions/workflow';
import createWithLoading from 'hocs/loading/createWithLoading';
import AppLoadingOverlay from 'components/reusable/Overalys/AppLoadingOverlay.layout';

import AppComponent from './App.layout';
import { AppDispatchProps, AppStateProps } from './App.types';

const loadingSelector = (state: State) => state.app.isAppLoading;

const mapStateToProps = (state: State): AppStateProps => ({
    isAuthenticated: state.user.isAuthenticated,
    isAppLoading: loadingSelector(state),
    xs2aAuthorisationId: state.workflow.xs2aAuthorisationId,
    authorisationType: state.workflow.authorisationType,
    workflowStep: state.workflow.workflowStep,
});

export const mapDispatchToProps = (dispatch: Dispatch): AppDispatchProps =>
    ({
        dispatchInitWorkflow: (xs2aAuthorisationId: Xs2aAuthorisationId | undefined) => dispatch(initWorkflow(xs2aAuthorisationId)),
        dispatchFinishWorkflow: () => dispatch(finishWorkflow())
    });

const decorate = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
);

export default decorate(createWithLoading(AppComponent, { loadingSelector, OverlayComponent: AppLoadingOverlay }));
