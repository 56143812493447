import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const StandardTooltip = styled(ReactTooltip)`
  max-width: 300px;
  text-align: center;
  
  &.show {
    opacity: 1;
  }
`;

export default { StandardTooltip };
