import { WithT } from 'i18next';

import { Account, ScopeType } from 'models/domain/xs2a';
import Icons from 'assets/styles/icons/icons';


interface AccessObjectDefinition {
    icon: keyof typeof Icons;
    titleKey: string;
}

type AccessObjectDefinitions = Record<ScopeType, AccessObjectDefinition>;

export const accessObjects: AccessObjectDefinitions = {
    [ScopeType.DETAILS]: { icon: 'search', titleKey: 'accountObject.detailsIconTooltip' },
    [ScopeType.BALANCES]: { icon: 'wallet', titleKey: 'accountObject.balancesIconTooltip' },
    [ScopeType.TRANSACTIONS]: { icon: 'shoppingCart', titleKey: 'accountObject.transactionsIconTooltip' }
};

export type AccountItemProps = Account & WithT;
