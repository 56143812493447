import React, { FunctionComponent } from 'react';
import { formatIBAN } from 'utils/text-formatters';
import Icons from 'assets/styles/icons/icons';

import Styled from './AccountItem.styled';
import { accessObjects, AccountItemProps } from './AccountItem.types';


const AccountItem: FunctionComponent<AccountItemProps> = props => {
    const { iban, accountName, accessScope, t } = props;

    const accessScopeIcons = accessScope.map(scopeType => {
        const definition = accessObjects[scopeType];
        return (
            <Styled.AccessIcon
                key={scopeType}
                icon={Icons[definition.icon]}
                color="accent.light"
                data-tip={t(definition.titleKey)}
            />
        );
    });

    return (
        <Styled.AccountItemWrap>
            <Styled.AccountNameAndIBAN>
                <Styled.AccountName>{accountName}</Styled.AccountName>
                <Styled.AccountIBAN>{formatIBAN(iban)}</Styled.AccountIBAN>
            </Styled.AccountNameAndIBAN>

            <Styled.AccountAccessScope>
                <Styled.AccountAccessScopeLabel>{t('accountObject.accessScopeLabel')}</Styled.AccountAccessScopeLabel>
                <Styled.AccountAccessScopeIconsWrap>{accessScopeIcons}</Styled.AccountAccessScopeIconsWrap>
            </Styled.AccountAccessScope>
        </Styled.AccountItemWrap>
    );
};

export default AccountItem;
