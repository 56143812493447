import { SmsCodeFormState } from 'models/app/store';
import {
    SmsCodeFormActions,
    SET_SMS_FIELD_VALUE,
    CLEAR_SMS_CODE_FORM,
    SET_SMS_CODE_FORM_ERROR,

    SetSmsFieldValuePayload,
} from 'store/actions/smsCodeForm.types';

import { createReducer, Handlers } from './reducer';
import {
    AuthorisationActions,
    VERIFY_CODE,
    VERIFY_CODE_FAILURE,
    VERIFY_CODE_SUCCESS,
} from '../actions/authorisation.types';

export const initialState: SmsCodeFormState = {
    digit1Value: '',
    digit2Value: '',
    digit3Value: '',
    digit4Value: '',
    digit5Value: '',
    digit6Value: '',
    isLoading: false,
    hasError: false,
};

const handlers: Handlers<SmsCodeFormState, SmsCodeFormActions & AuthorisationActions> = {
    [SET_SMS_FIELD_VALUE]: (payload: SetSmsFieldValuePayload) => {
        return { [payload.fieldName]: payload.value };
    },

    [VERIFY_CODE]: () => {
        return { isLoading: true };
    },

    [VERIFY_CODE_SUCCESS]: () => {
        return { isLoading: false };
    },

    [VERIFY_CODE_FAILURE]: () => {
        return { isLoading: false };
    },

    [CLEAR_SMS_CODE_FORM]: () => {
        const { isLoading, ...rest } = initialState;
        return rest;
    },

    [SET_SMS_CODE_FORM_ERROR]: (hasError: boolean) => {
        return { hasError };
    },

};

export default createReducer(initialState, handlers);
