import styled, { ThemeProps } from 'styled-components';

import { Theme } from 'config/theme';
import { BorderColorTransition } from 'assets/styles/animations/animations.styled';
import { ErrorProp } from '../FormField.types';


const getErrorBorderColor = ({ theme }: ThemeProps<Theme>) => ({ borderColor: theme.colors.error.base });

const getBorderColor = ({ theme }: ThemeProps<Theme>) => ({ borderColor: theme.colors.gray.base });

const getFocusBorderColor = ({ theme }: ThemeProps<Theme>) => ({ borderColor: theme.colors.accent.light });

const Input = styled.input<ErrorProp>`
    width: 100%;
    padding: 16px 60px 16px 23px;
    border: 1px solid;
    min-height: 16px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black};
    ${props => (props.hasError ? getErrorBorderColor(props) : getBorderColor)};
    ${BorderColorTransition};
        
    &:focus,
    &:hover {
      ${props => (props.hasError ? getErrorBorderColor(props) : getFocusBorderColor(props))};
    }
    
    ::placeholder {
        color: ${({ theme }) => theme.colors.gray.dark};
    }
`;


export default { Input };
