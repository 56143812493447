import { Xs2aAuthorisationId } from 'models/domain/xs2a';
import { WorkflowState } from 'models/app/store';
import { AuthorisationType, WorkflowStep } from 'models/app/workflow';
import { INIT_WORKFLOW, SET_WORKFLOW_STEP, START_WORKFLOW, WorkflowActions } from 'store/actions/workflow.types';
import { AuthorisationActions, START_AUTHORISATION_FAILURE } from 'store/actions/authorisation.types';
import { CLEAR_STORE, ClearStore } from 'store/actions/common.types';

import { createReducer, Handlers } from './reducer';


export const initialState: WorkflowState = {
    xs2aAuthorisationId: '',
    authorisationType: AuthorisationType.UNKNOWN,
    workflowStep: WorkflowStep.INIT
};

const handlers: Handlers<WorkflowState, WorkflowActions & AuthorisationActions & ClearStore> = {
    [INIT_WORKFLOW]: (xs2aAuthorisationId: Xs2aAuthorisationId | undefined, state: WorkflowState) => {
        if (state.workflowStep !== WorkflowStep.LOGIN) {
            const workflowStep = xs2aAuthorisationId ? WorkflowStep.LOGIN : WorkflowStep.BAD_REQUEST;
            return { xs2aAuthorisationId: xs2aAuthorisationId ?? '', workflowStep };
        } else {
            return undefined;
        }
    },

    [START_WORKFLOW]: (authorisationType: AuthorisationType) => {
        return { authorisationType };
    },

    [SET_WORKFLOW_STEP]: (step: WorkflowStep) => {
        return { workflowStep: step };
    },

    [START_AUTHORISATION_FAILURE]: () => {
        return { authorisationType: AuthorisationType.UNKNOWN };
    },

    [CLEAR_STORE]: () => {
        return initialState;
    }
};

export default createReducer(initialState, handlers);
