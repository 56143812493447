import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Dispatch } from 'models/meta/action';
import { finishWorkflow } from 'store/actions/workflow';

import { AuthorisationFailedDispatchProps } from './AuthorisationFailed.types';
import AuthorisationFailed from './AuthorisationFailed.layout';


const mapDispatchToProps = (dispatch: Dispatch): AuthorisationFailedDispatchProps =>
    ({ dispatchFinishWorkflow: () => dispatch(finishWorkflow()) });


export default connect(null, mapDispatchToProps)(withTranslation('result-screen')(AuthorisationFailed));
