import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const AppToastContainerWrap = styled(ToastContainer)`
    .Toastify__toast-container {
        width: 437px;
        border: 2px solid green;
    }

    .toastify-content {
        width: 437px;
    }

    > .Toastify__toast {
        padding: 0 16px 0 0;
        margin: 10em 0 0;
        position: absolute;
        right: 0;
        left: -4em;
        display: flex;
        align-items: center;
        box-shadow: ${({ theme }) => theme.boxShadow.containerBlurred};
    }

    > .Toastify__toast > .Toastify__close-button {
        align-self: initial;
    }
`;

export default { AppToastContainerWrap };
