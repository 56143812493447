import { css } from 'styled-components';

const standardDuration = '0.25s';

export const BgColorTransition = css`
    transition: background-color ${standardDuration} linear;
`;

export const ColorTransition = css`
    transition: color ${standardDuration} linear;
`;

export const FontSizeTransition = css`
    transition: font-size ${standardDuration} linear;
`;

export const BorderColorTransition = css`
    transition: border-top-color ${standardDuration} linear, 
                border-right-color ${standardDuration} linear,
                border-bottom-color ${standardDuration} linear, 
                border-left-color ${standardDuration} linear;
`;
