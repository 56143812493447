import React from 'react';
import Styled from './Profile.styled';
import { ProfileProps } from './Profile.types';

function Profile({
    t,
    userName,
    profileDisplayName
}: ProfileProps) {

    return (
        <Styled.ProfileWrap>
            <Styled.ProfileLabel>{t('loggedInAs')}</Styled.ProfileLabel>
            <div>
                <Styled.ProfileName>{userName}</Styled.ProfileName>
                <Styled.ProfileCompanyName>{profileDisplayName}</Styled.ProfileCompanyName>
            </div>
        </Styled.ProfileWrap>
    );
}

export default Profile;
