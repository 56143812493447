import { ofType } from 'redux-observable';
import { switchMap, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import i18next from 'i18next';

import { Epic } from 'models/meta/epic';
import {
    NAVIGATE,
    SHOW_ERROR_TOAST,
    SHOW_INFO_TOAST,
    SHOW_SUCCESS_TOAST,
    SHOW_WARN_TOAST
} from 'store/actions/common.types';
import showToast from 'components/reusable/Toast/AppToast/AppToast.layout';
import renderToastMessage from 'components/reusable/Toast/Toast.layout';


export const onShowSuccessToast: Epic = action$ =>
    action$.pipe(
        ofType(SHOW_SUCCESS_TOAST),
        switchMap(({ payload: { title, desc, options } }) => {
            showToast(renderToastMessage(title, desc), 'SUCCESS', options);
            return EMPTY;
        })
    );
export const onShowInfoToast: Epic = action$ =>
    action$.pipe(
        ofType(SHOW_INFO_TOAST),
        switchMap(({ payload: { title, desc, options } }) => {
            showToast(renderToastMessage(i18next.t(title), i18next.t(desc)), 'INFO', options);
            return EMPTY;
        })
    );

export const onShowWarnToast: Epic = action$ =>
    action$.pipe(
        ofType(SHOW_WARN_TOAST),
        switchMap(({ payload: { title, desc, options } }) => {
            showToast(renderToastMessage(i18next.t(title), i18next.t(desc)), 'WARN', options);
            return EMPTY;
        })
    );

export const onShowErrorToast: Epic = action$ =>
    action$.pipe(
        ofType(SHOW_ERROR_TOAST),
        switchMap(({ payload: { title, desc, options } }) => {
            showToast(renderToastMessage(i18next.t(title), i18next.t(desc)), 'DANGER', options);
            return EMPTY;
        })
    );

export const onNavigationRequested: Epic = (action$, _, { history }) => action$.pipe(
    ofType(NAVIGATE),

    tap(({ payload }) => {
        if (/^.+?:\/\//.test(payload)) {
            window.location.href = payload;
        } else {
            history.replace(payload);
        }
    }),

    switchMap(() => EMPTY)
);
