import { AuthorisationDetails } from 'models/domain/xs2a';
import { Xs2aState } from 'models/app/store';
import { CLEAR_STORE, ClearStore } from 'store/actions/common.types';
import { PREPARE_AUTHORISATION_DETAILS_SUCCESS, Xs2aActions } from 'store/actions/xs2a.types';

import { createReducer, Handlers } from './reducer';


const initialAuthorisationDetails: AuthorisationDetails = {
    profileId: '',
    profileDisplayName: '',
    tppName: '',
    tppRedirectUrl: '',
};

export const initialState: Xs2aState = { authorisation: initialAuthorisationDetails };

const handlers: Handlers<Xs2aState, Xs2aActions & ClearStore> = {
    [PREPARE_AUTHORISATION_DETAILS_SUCCESS]: (authorisation: AuthorisationDetails) => {
        return { authorisation };
    },
    [CLEAR_STORE]: () => {
        return initialState;
    },

};

export default createReducer(initialState, handlers);
