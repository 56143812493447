import { AuthorisationDetails, Xs2aAuthorisationId } from 'models/domain/xs2a';
import { SignInCredentials, User } from 'models/domain/user';
import { loginUrl } from 'models/meta/api';

import { Xs2aHttpService } from '../HttpService';


export const xs2a = {
    prepareAuthorisation: (xs2aAuthorisationId: Xs2aAuthorisationId): Promise<AuthorisationDetails> => {
        return Xs2aHttpService.post(`v1/internal/authorisations/${xs2aAuthorisationId}`, {}, { noErrorHandling: true });
    },
    signIn: (credentials: SignInCredentials): Promise<User> => {
        return Xs2aHttpService.post(loginUrl, credentials, { noErrorHandling: true });
    },

};
