import { connect } from 'react-redux';

import { State } from 'models/app/store';
import { setLoginLanguage } from 'store/actions/login';
import { Dispatch } from 'models/meta/action';

import { LanguageChangerDispatchProps, LanguageChangerStateProps } from './LanguageChanger.types';
import LanguageChanger from './LanguageChanger.layout';


const mapStateToProps = (state: State): LanguageChangerStateProps =>
    ({ language: state.login.language });

const mapDispatchToProps = (dispatch: Dispatch): LanguageChangerDispatchProps =>
    ({ dispatchSetLoginLanguage: (locale: string) => dispatch(setLoginLanguage(locale)) });


export default connect(mapStateToProps, mapDispatchToProps)(LanguageChanger);
