import { Action } from 'models/meta/action';
import { Xs2aAuthorisationId } from 'models/domain/xs2a';
import { AuthorisationType, WorkflowStep } from 'models/app/workflow';


type INIT_WORKFLOW = 'INIT_WORKFLOW';
type START_WORKFLOW = 'START_WORKFLOW';
type FINISH_WORKFLOW = 'FINISH_WORKFLOW';
type SET_WORKFLOW_STEP = 'SET_WORKFLOW_STEP';

export const INIT_WORKFLOW: INIT_WORKFLOW = 'INIT_WORKFLOW';
export const START_WORKFLOW: START_WORKFLOW = 'START_WORKFLOW';
export const FINISH_WORKFLOW: FINISH_WORKFLOW = 'FINISH_WORKFLOW';
export const SET_WORKFLOW_STEP: SET_WORKFLOW_STEP = 'SET_WORKFLOW_STEP';


export interface InitWorkflow extends Action {
    type: INIT_WORKFLOW;
    payload: Xs2aAuthorisationId | undefined;
}

export interface StartWorkflow extends Action {
    type: START_WORKFLOW;
    payload: AuthorisationType;
}

export interface FinishWorkflow extends Action {
    type: FINISH_WORKFLOW;
}

export interface SetWorkflowStep extends Action {
    type: SET_WORKFLOW_STEP;
    payload: WorkflowStep,
}


export type WorkflowActions = InitWorkflow | StartWorkflow | FinishWorkflow | SetWorkflowStep;
