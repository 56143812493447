import React from 'react';

import { AnimatedLabelProps } from './AnimatedLabel.types';
import Styled from './AnimatedLabel.styled';

export default function AnimatedLabel({
    hasError,
    hasValue,
    text
}: AnimatedLabelProps) {

    return (
        <Styled.AnimatedLabel
            hasError={hasError}
            className="input-label"
            hasValue={hasValue}
        >
            {text}
        </Styled.AnimatedLabel>
    );
}
