import { AuthorisationId } from 'models/domain/common';
import { Xs2aAuthorisationId } from 'models/domain/xs2a';
import {
    AuthorisationStatus,
    SmsCode,
    StartAuthorisationResponse,
    VerifyCodeResponse
} from 'models/domain/authorisation';
import { AuthorisationType, toActionSource, toActionType } from 'models/app/workflow';
import { authorisation } from 'services/api/authorisation';


class AuthorisationService {
    static startAuthorisation(authorisationType: AuthorisationType, xs2aAuthorisationId?: Xs2aAuthorisationId): Promise<StartAuthorisationResponse> {
        return authorisation.startAuthorisation(toActionSource(authorisationType), toActionType(authorisationType), { xs2aAuthorisationId });
    }

    static cancelAuthorisation(authorisationType: AuthorisationType, authorisationId: AuthorisationId): Promise<void> {
        return authorisation.cancelAuthorisation(toActionSource(authorisationType), toActionType(authorisationType), authorisationId);
    }

    static getAuthorisationStatus(authorisationType: AuthorisationType, authorisationId: AuthorisationId): Promise<AuthorisationStatus> {
        return authorisation.getAuthorisationStatus(toActionSource(authorisationType), toActionType(authorisationType), authorisationId)
            .then(response => response.authorisationStatus);
    }

    static verifyCode(authorisationType: AuthorisationType, authorisationId: AuthorisationId, code: SmsCode): Promise<VerifyCodeResponse> {
        return authorisation.verifyCode(toActionSource(authorisationType), toActionType(authorisationType), authorisationId, { code });
    }

    static resendCode(authorisationType: AuthorisationType, authorisationId: AuthorisationId): Promise<AuthorisationStatus> {
        return authorisation.resendCode(toActionSource(authorisationType), toActionType(authorisationType), authorisationId)
            .then(response => response.authorisationStatus);
    }

}

export default AuthorisationService;
