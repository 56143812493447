import styled from 'styled-components';

const AttributesWrap = styled.section`
  display: flex;
  flex: 1 1 33.3%;
  justify-content: space-between;
  align-items: center;
`;

const AccountListWrap = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px 0 37px;
`;

export default { AttributesWrap, AccountListWrap };
