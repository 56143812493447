import { AuthTokens, SignInCredentials, User } from 'models/domain/user';

import {
    REFRESH_TOKEN,
    REFRESH_TOKEN_FAILURE,
    REFRESH_TOKEN_SUCCESS,
    RefreshToken,
    RefreshTokenFailure,
    RefreshTokenSuccess,
    SIGN_OFF,
    SIGN_ON,
    SIGN_ON_FAILURE,
    SIGN_ON_SUCCESS,
    SignOff,
    SignOn,
    SignOnFailure,
    SignOnSuccess
} from './user.types';


export const signOn = (credentials: SignInCredentials): SignOn => ({ type: SIGN_ON, payload: { credentials } });
export const signOnSuccess = (user: User): SignOnSuccess => ({ type: SIGN_ON_SUCCESS, payload: user });
export const signOnFailure = (): SignOnFailure => ({ type: SIGN_ON_FAILURE });

export const signOff = (): SignOff => ({ type: SIGN_OFF });

export const refreshToken = (): RefreshToken => ({ type: REFRESH_TOKEN });
export const refreshTokenSuccess = (tokens: AuthTokens): RefreshTokenSuccess => ({ type: REFRESH_TOKEN_SUCCESS, payload: tokens });
export const refreshTokenFailure = (unauthorized: boolean): RefreshTokenFailure => ({ type: REFRESH_TOKEN_FAILURE, payload: { unauthorized } });
