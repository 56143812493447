import React, { FormEvent, useRef } from 'react';
import { Trans } from 'react-i18next';

import { Button } from 'components/reusable/Buttons/Button';
import TextButton from 'components/reusable/Buttons/TextButton.styled';
import { noop } from 'utils/function-tools';
import Icons from 'assets/styles/icons/icons';

import Styled from './SmsCodeForm.styled';
import { FormFieldTargetRef, FormItemDefinition, isFormSeparator, SmsCodeFormProps } from './SmsCodeForm.types';


const SmsCodeForm: React.FC<SmsCodeFormProps> = props => {
    const {
        t,
        isLoading,
        hasError,

        digit1Value,
        digit2Value,
        digit3Value,
        digit4Value,
        digit5Value,
        digit6Value,

        currentDayAuthorisationsCounter: operationNumber,

        dispatchSetSmsFieldValue,
        dispatchVerifyCode,
        dispatchCancelAuthorisation,
        dispatchResendSmsCode,
        dispatchSetSmsCodeFormError,
        dispatchClearSmsCodeForm
    } = props;

    const date = new Date().toISOString().split('T')[0];

    const refSubmit = useRef<typeof Button>(null);
    const refInputDigit1 = useRef<HTMLInputElement>(null);
    const refInputDigit2 = useRef<HTMLInputElement>(null);
    const refInputDigit3 = useRef<HTMLInputElement>(null);
    const refInputDigit4 = useRef<HTMLInputElement>(null);
    const refInputDigit5 = useRef<HTMLInputElement>(null);
    const refInputDigit6 = useRef<HTMLInputElement>(null);

    const isValid = !!digit1Value && !!digit2Value && !!digit3Value && !!digit4Value && !!digit5Value && !!digit6Value;

    const isSubmitDisabled = isLoading || !isValid || hasError;

    const onSubmit = (event: FormEvent): void => {
        event.preventDefault();

        if (isValid) {
            dispatchVerifyCode(`${digit1Value}${digit2Value}${digit3Value}${digit4Value}${digit5Value}${digit6Value}`);
        }
    };

    const onInputKeyDown = (event: React.KeyboardEvent<HTMLElement>, fieldName: string,
        prevRef: FormFieldTargetRef, nextRef: FormFieldTargetRef) => {
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        const { key } = event;

        if (allowedKeys.includes(key)) {
            dispatchSetSmsFieldValue({ value: key, fieldName });

            setTimeout(() => {
                const targetElement = nextRef.current;
                if (targetElement) targetElement.focus();
            });

        } else if (key === 'Backspace') {
            dispatchSetSmsFieldValue({ value: '', fieldName });

            setTimeout(() => {
                const targetElement = prevRef.current;
                if (targetElement) targetElement.focus();
            });
        }
    };

    const onSubmitKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Backspace') {
            onInputKeyDown(event, 'digit6Value', refInputDigit6, refSubmit);
        }
    };

    const onFocus = () => {
        if (hasError) {
            dispatchClearSmsCodeForm();
            dispatchSetSmsCodeFormError(false);
        }
    };

    const formItems: FormItemDefinition[] = [
        { type: 'field', key: 'digit1Value', value: digit1Value, ownRef: refInputDigit1, prevRef: refInputDigit1, nextRef: refInputDigit2, autoFocus: true },
        { type: 'field', key: 'digit2Value', value: digit2Value, ownRef: refInputDigit2, prevRef: refInputDigit1, nextRef: refInputDigit3, pad: true },
        { type: 'field', key: 'digit3Value', value: digit3Value, ownRef: refInputDigit3, prevRef: refInputDigit2, nextRef: refInputDigit4, pad: true },
        { type: 'separator', key: 'separator' },
        { type: 'field', key: 'digit4Value', value: digit4Value, ownRef: refInputDigit4, prevRef: refInputDigit3, nextRef: refInputDigit5 },
        { type: 'field', key: 'digit5Value', value: digit5Value, ownRef: refInputDigit5, prevRef: refInputDigit4, nextRef: refInputDigit6, pad: true },
        { type: 'field', key: 'digit6Value', value: digit6Value, ownRef: refInputDigit6, prevRef: refInputDigit5, nextRef: refSubmit, pad: true }
    ];

    return (
        <Styled.FormWrap>
            <Styled.Header>
                <Trans
                    t={t}
                    i18nKey="smsConfirmationCodeWindow.header"
                    ns="common"
                    values={{ operationNumber, date }}
                    // @ts-ignore — improper react-i18next typings as for 11.6.0
                    // (cf. https://react.i18next.com/latest/trans-component#alternative-usage-v-11-6-0)
                    components={{ strong: <Styled.Strong /> }}
                />
            </Styled.Header>

            <Styled.SubHeader>{t('common:smsConfirmationCodeWindow.subHeader')}</Styled.SubHeader>

            <Styled.Form onSubmit={onSubmit}>
                <Styled.Fieldset>
                    { formItems.map((formItem) => {
                        if (isFormSeparator(formItem)) {
                            return <Styled.Dash key={formItem.key} />;
                        } else {
                            const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) =>
                                onInputKeyDown(event, formItem.key, formItem.prevRef, formItem.nextRef);

                            return (
                                <Styled.DigitInput
                                    key={formItem.key}
                                    ref={formItem.ownRef}
                                    autoFocus={formItem.autoFocus}
                                    type="number"
                                    error={hasError}
                                    marginLeft={formItem.pad ? '5px' : undefined}
                                    placeholder={hasError ? formItem.value : ''}
                                    value={hasError ? '' : formItem.value}
                                    onChange={noop}
                                    onFocus={onFocus}
                                    onKeyDown={onKeyDown}
                                />
                            );
                        }
                    })}
                </Styled.Fieldset>


                <Styled.ErrorWrap>
                    {hasError && (
                        <>
                            <Styled.ErrorIcon icon={Icons.closeCircle} color="error.base" />
                            <Styled.ErrorMsg>{t('validationMsg.incorrectCode')}</Styled.ErrorMsg>
                        </>
                    )}
                </Styled.ErrorWrap>

                <Styled.SubmitWrap>
                    <Button
                        ref={refSubmit}
                        type="submit"
                        disabled={isSubmitDisabled}
                        onKeyDown={onSubmitKeyDown}
                    >
                        {t('buttons.submit.text')}
                    </Button>
                </Styled.SubmitWrap>
            </Styled.Form>

            <Styled.SecondaryActionWrap>
                {t('smsConfirmationCodeWindow.lackOfMessageText')}
                <TextButton onClick={() => dispatchResendSmsCode()}>{t('buttons.resendCode.text')}</TextButton>
            </Styled.SecondaryActionWrap>

            <Styled.SecondaryActionWrap>
                {t('smsConfirmationCodeWindow.cancellationText')}
                <TextButton onClick={() => dispatchCancelAuthorisation()}>{t('buttons.cancelAuthorisation.text')}</TextButton>
            </Styled.SecondaryActionWrap>

        </Styled.FormWrap>
    );
};

export default SmsCodeForm;
