import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, switchMapTo } from 'rxjs/operators';

import { defaultRoutePath, defaultTppRedirectUrl } from 'config/config';
import { Epic } from 'models/meta/epic';
import { clearStore, navigate } from 'store/actions/common';
import { signOff } from 'store/actions/user';
import { FINISH_WORKFLOW, INIT_WORKFLOW } from 'store/actions/workflow.types';


export const onInitWorkflow: Epic = action$ =>
    action$.pipe(
        ofType(INIT_WORKFLOW),
        switchMapTo(of(navigate(defaultRoutePath)))
    );

export const onFinishWorkflow: Epic = (action$, state$) =>
    action$.pipe(
        ofType(FINISH_WORKFLOW),
        switchMap(() => {
            const { tppRedirectUrl } = state$.value.xs2a.authorisation;
            const returnLink = tppRedirectUrl || defaultTppRedirectUrl;

            return of(signOff(), clearStore(), navigate(returnLink));
        })
    );
