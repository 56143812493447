import i18next from 'i18next';

import { Action } from 'models/meta/action';
import {
    CLEAR_STORE,
    ClearStore,
    Navigate,
    NAVIGATE,
    SHOW_ERROR_TOAST,
    SHOW_INFO_TOAST,
    SHOW_SUCCESS_TOAST,
    SHOW_WARN_TOAST
} from './common.types';


export const navigate = (location: string): Navigate => {
    return { type: NAVIGATE, payload: location };
};

export const clearStore = (): ClearStore => ({ type: CLEAR_STORE });
export const showSuccessToast = (title: string, desc: string): Action => ({
    type: SHOW_SUCCESS_TOAST,
    payload: {
        title,
        desc
    }
});

export const showInfoToast = (title: string, desc: string): Action => ({
    type: SHOW_INFO_TOAST,
    payload: {
        title,
        desc
    }
});

export const showWarnToast = (title: string, desc: string): Action => ({
    type: SHOW_WARN_TOAST,
    payload: {
        title,
        desc
    }
});
export const showErrorToast = (title: string, desc: string): Action => ({
    type: SHOW_ERROR_TOAST,
    payload: {
        title,
        desc
    }
});

const roots = ['actionMsg', 'actionMessages'];
const actionMessage = (ns: string, actionName: string, item: string, params?: Record<string, unknown>) =>
    i18next.t(roots.map(root => `${ns}:${root}.${actionName}.${item}`), params);

export const actionSuccessToast = (ns: string = 'common', actionName: string = 'genericSuccessMessage', params?: Record<string, unknown>) =>
    showSuccessToast(actionMessage(ns, actionName, 'title', params), actionMessage(ns, actionName, 'text', params));

export const actionInfoToast = (ns: string = 'common', actionName: string = 'genericSuccessMessage', params?: Record<string, unknown>) =>
    showInfoToast(actionMessage(ns, actionName, 'title', params), actionMessage(ns, actionName, 'text', params));

export const actionWarnToast = (ns: string = 'common', actionName: string = 'genericErrorMessage', params?: Record<string, unknown>) =>
    showWarnToast(actionMessage(ns, actionName, 'title', params), actionMessage(ns, actionName, 'text', params));

export const actionErrorToast = (ns: string = 'common', actionName: string = 'genericErrorMessage', params?: Record<string, unknown>) =>
    showErrorToast(actionMessage(ns, actionName, 'title', params), actionMessage(ns, actionName, 'text', params));
