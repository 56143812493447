import { AuthorisationType, WorkflowStep } from 'models/app/workflow';
import { Xs2aAuthorisationId } from 'models/domain/xs2a';

import {
    FINISH_WORKFLOW,
    FinishWorkflow,
    INIT_WORKFLOW,
    InitWorkflow,
    SET_WORKFLOW_STEP,
    SetWorkflowStep,
    START_WORKFLOW,
    StartWorkflow
} from './workflow.types';


export const initWorkflow = (xs2aAuthorisationId: Xs2aAuthorisationId | undefined): InitWorkflow =>
    ({ type: INIT_WORKFLOW, payload: xs2aAuthorisationId });

export const startWorkflow = (authorisationType: AuthorisationType): StartWorkflow =>
    ({ type: START_WORKFLOW, payload: authorisationType });

export const finishWorkflow = (): FinishWorkflow =>
    ({ type: FINISH_WORKFLOW });

export const setWorkflowStep = (step: WorkflowStep): SetWorkflowStep =>
    ({ type: SET_WORKFLOW_STEP, payload: step });
