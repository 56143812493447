import React, { FunctionComponent } from 'react';

import logo from 'assets/img/logo.svg';
import Tooltip from 'components/reusable/Tooltip/Tooltip.layout';
import NavigationBar from './NavigationBarDecoration/NavigationBarDecoration.layout';
import Footer from './Footer/Footer';
import Links from './Links/Links';
import Styled from './LoginLayout.styled';

const LoginLayoutLayout: FunctionComponent = ({ children }) => {
    return (
        <>
            <NavigationBar />

            <Styled.LoginMainContentWrap>
                <Styled.LoginMainContent>

                    <Styled.AppLogoWrap>
                        <img src={logo} alt="OKEO symbol" height={60} width={200} />
                    </Styled.AppLogoWrap>

                    { children }

                    <Links />

                </Styled.LoginMainContent>
            </Styled.LoginMainContentWrap>

            <Footer />

            <Tooltip />
        </>

    );
};

export default LoginLayoutLayout;
