import { AuthorisationId } from 'models/domain/common';
import {
    ActionSource,
    ActionType,
    GetAuthorisationStatusResponse,
    ResendCodeResponse,
    StartAuthorisationRequest,
    StartAuthorisationResponse,
    VerifyCodeRequest,
    VerifyCodeResponse
} from 'models/domain/authorisation';

import { ApiHttpService } from '../HttpService';


export const authorisation = {
    startAuthorisation: (actionSource: ActionSource, actionType: ActionType, request: StartAuthorisationRequest): Promise<StartAuthorisationResponse> => {
        return ApiHttpService.post(`api/authorisation/v1/${actionSource}/${actionType}`, request);
    },

    cancelAuthorisation: (actionSource: ActionSource, actionType: ActionType, authorisationId: AuthorisationId): Promise<void> => {
        return ApiHttpService.delete(`api/authorisation/v1/${actionSource}/${actionType}/${authorisationId}`);
    },

    getAuthorisationStatus: (actionSource: ActionSource, actionType: ActionType, authorisationId: AuthorisationId): Promise<GetAuthorisationStatusResponse> => {
        return ApiHttpService.get(`api/authorisation/v1/${actionSource}/${authorisationId}/status`);
    },

    verifyCode: (actionSource: ActionSource, actionType: ActionType, authorisationId: AuthorisationId, request: VerifyCodeRequest): Promise<VerifyCodeResponse> => {
        return ApiHttpService.put(`api/authorisation/v1/${actionSource}/${actionType}/${authorisationId}/code`, request);
    },

    resendCode: (actionSource: ActionSource, actionType: ActionType, authorisationId: AuthorisationId): Promise<ResendCodeResponse> => {
        return ApiHttpService.post(`api/authorisation/v1/${actionSource}/${actionType}/${authorisationId}/code`, {});
    }

};
