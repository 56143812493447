import styled from 'styled-components';
import { MainContentWrap } from 'assets/styles/global/layout.styled';

const LoginMainContentWrap = styled(MainContentWrap)`
    min-height: 100vh;
    padding-top: 25px;
`;

const LoginMainContent = styled.div`
    width: 488px;
    margin: 0 auto;

`;

const AppLogoWrap = styled.div`
    padding: 50px 0 65px;
    user-select: none;
    pointer-events: none;
    text-align: center;
`;

export default { LoginMainContentWrap, LoginMainContent, AppLogoWrap };
