import { AuthorisationState } from 'models/app/store';
import {
    AuthorisationStatus,
    ResendCodeResponse,
    StartAuthorisationResponse,
    VerificationResult,
    VerifyCodeResponse
} from 'models/domain/authorisation';
import { CLEAR_STORE, ClearStore } from 'store/actions/common.types';
import {
    AuthorisationActions,
    RESEND_CODE_SUCCESS,
    START_AUTHORISATION_SUCCESS,
    VERIFY_CODE_SUCCESS
} from 'store/actions/authorisation.types';

import { createReducer, Handlers } from './reducer';


export const initialState: AuthorisationState = {
    authorisationId: '',
    verificationResult: VerificationResult.FAILED,
    authorisationStatus: AuthorisationStatus.CANCELED,
    currentDayAuthorisationsCounter: 0,
};

const handlers: Handlers<AuthorisationState, AuthorisationActions & ClearStore> = {
    [START_AUTHORISATION_SUCCESS]: (payload: StartAuthorisationResponse) => {
        return { ...payload };
    },

    [VERIFY_CODE_SUCCESS]: (payload: VerifyCodeResponse) => {
        return { ...payload };
    },

    [RESEND_CODE_SUCCESS]: (payload: ResendCodeResponse) => {
        return { ...payload };
    },

    [CLEAR_STORE]: () => {
        return initialState;
    },


};

export default createReducer(initialState, handlers);
