import { AuthorisationId } from './common';
import { Xs2aAuthorisationId } from './xs2a';

export enum ActionSource {
    XS2A = 'xs2a',
    APP = 'webspa-sca'
}

export enum ActionType {
    LOGIN = 'login',
    TPP_CONSENT = 'tpp-consent',
    TPP_PAYMENT = 'tpp-payment'
}

export enum VerificationResult {
    PASSED = 'PASSED',
    FAILED = 'FAILED'
}

export enum AuthorisationStatus {
    STARTED = 'STARTED',
    CANCELED = 'CANCELED',
    TOO_MANY_TRIES = 'TOO_MANY_TRIES',
    TOO_MANY_SMSES = 'TOO_MANY_SMSES',
    TIMEOUT = 'TIMEOUT',
    FINALISED = 'FINALISED',
}

export type SmsCode = string; // 6 digits

export interface StartAuthorisationRequest {
    xs2aAuthorisationId?: Xs2aAuthorisationId;
}

export interface StartAuthorisationResponse {
    authorisationId: AuthorisationId;
    authorisationMethod: 'SMS_CODE';
    currentDayAuthorisationCounter: number; //  int (positive), max 10
}

export interface GetAuthorisationStatusResponse {
    authorisationStatus: AuthorisationStatus;
}

export interface VerifyCodeRequest {
    code: SmsCode;
}

export interface VerifyCodeResponse {
    verificationResult: VerificationResult;
    authorisationStatus: AuthorisationStatus;
}

export interface ResendCodeResponse {
    authorisationStatus: AuthorisationStatus;
}
