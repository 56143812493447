import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { State } from 'models/app/store';

import { ProfileStateProps } from './Profile.types';
import Profile from './Profile.layout';


const mapStateToProps = (state: State): ProfileStateProps => ({
    userName: state.user.data.shortName || '',
    profileDisplayName: state.xs2a.authorisation.profileDisplayName,
});

export default connect(mapStateToProps)(withTranslation('common')(Profile));
