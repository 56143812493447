import { css } from 'styled-components';

export const ScrollBar = css`
    ::-webkit-scrollbar {
        width: 4px;
        background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: #8a8a8a;
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #f5f5f5;
    }
`;
