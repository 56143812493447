import { combineEpics } from 'redux-observable';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { developmentMode } from 'config/config';
import { Epic } from 'models/meta/epic';

import {
    onNavigationRequested,
    onShowErrorToast,
    onShowInfoToast,
    onShowSuccessToast,
    onShowWarnToast
} from './commonEpic';

import {
    onReceivedTokens,
    onSignOff,
    onSignOn,
    onSignOnSuccess,
    onTokenRefresh,
    onTokenRefreshFailed
} from './userEpic';

import { onPrepareAuthorisationDetails, onPrepareAuthorisationDetailSuccess } from './xs2aEpic';

import {
    onCancelAuthorisation,
    onCancelAuthorisationSuccess,
    onResendSmsCode,
    onResendSmsCodeSuccess,
    onStartAuthorisation,
    onVerifyCode,
    onVerifyCodeSuccess
} from './authorisationEpic';

import { onLoadingBegin, onLoadingEnd } from './appEpic';
import { onFinishWorkflow, onInitWorkflow } from './workflowEpic';


const commonEpics: Epic[] = [
    onShowSuccessToast,
    onShowInfoToast,
    onShowWarnToast,
    onShowErrorToast,
    onNavigationRequested
];

const userEpics: Epic[] = [
    onSignOn,
    onSignOnSuccess,
    onSignOff,
    onReceivedTokens,
    onTokenRefresh,
    onTokenRefreshFailed,
];

const loadingEpics: Epic[] = [
    onLoadingBegin,
    onLoadingEnd
];

const xs2aEpics: Epic[] = [
    onPrepareAuthorisationDetails,
    onPrepareAuthorisationDetailSuccess
];

const authorisationEpics: Epic[] = [
    onStartAuthorisation,
    onCancelAuthorisation,
    onCancelAuthorisationSuccess,
    onResendSmsCode,
    onResendSmsCodeSuccess,
    onVerifyCode,
    onVerifyCodeSuccess,
];

const workflowEpics: Epic[] = [
    onInitWorkflow,
    onFinishWorkflow
];

const epics: Epic[] = [
    ...commonEpics,
    ...loadingEpics,
    ...workflowEpics,
    ...userEpics,
    ...xs2aEpics,
    ...authorisationEpics,
];

export const areEpicsDoubled = Array.from(new Set(epics)).length !== epics.length;

if (developmentMode && areEpicsDoubled) {
    throw Error("Doubled epics!");
}

const onAnyEpic: Epic = combineEpics(...epics);

function errorHandler<T>(error: Error, source: Observable<T>): Observable<T> {
    if (developmentMode) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return source;
}

const onEpic: Epic = (action$, store$, dependencies) => onAnyEpic(action$, store$, dependencies).pipe(catchError(errorHandler));

export default onEpic;
