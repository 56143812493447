import React from 'react';

import Heading from 'components/reusable/authorization/Heading/Heading.layout';
import { Button } from 'components/reusable/Buttons/Button';
import ButtonsWrap from 'components/reusable/Buttons/ButtonsWrap.styled';

import { PaymentFinalisedProps } from './PaymentFinalised.types';


export default function PaymentFinalised(props: PaymentFinalisedProps) {
    const { t } = props;

    return (
        <main>
            <Heading
                pageHeader={t('paymentInitiated.header')}
                iconName="checkMarkCircle"
                iconColor="accent.light"
                pageSubheader={t('paymentInitiated.subHeader')}
            />

            <ButtonsWrap>
                <Button onClick={() => props.dispatchFinishWorkflow()}>{t('common:buttons.redirectBack.text')}</Button>
            </ButtonsWrap>
        </main>
    );
}
