import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { AsyncValidateCallback, reduxForm, getFormAsyncErrors } from 'redux-form';

import yup from 'yup';
import { asyncValidate as yupValidate } from 'redux-form-yup';

import { State } from 'models/app/store';
import { Dispatch } from 'models/meta/action';
import { signOn } from 'store/actions/user';

import { LoginDispatchProps, LOGIN_FORM, LoginStateProps, schema } from './LoginAuthorisation.types';
import { LoginAuthorisation } from './LoginAuthorisation.layout';


const validateAgainstSchema = yupValidate(schema);

export const shouldAsyncValidate = ({ trigger }: Pick<AsyncValidateCallback<{}, {}>, 'trigger'>) => {
    return trigger === 'submit' || trigger === 'blur';
};

export const asyncValidate = (values: yup.InferType<typeof schema>) => {

    return new Promise((resolve, reject) => {
        return validateAgainstSchema(values).then((errors) => {
            if (errors) {
                reject(errors);
            } else {
                resolve();
            }
        });
    });
};

const mapStateToProps = (state: State): LoginStateProps => ({
    fieldsValues: state.form?.[LOGIN_FORM]?.values || {},
    formErrors: getFormAsyncErrors(LOGIN_FORM)(state) || {},
    loginStep: state.login.loginStep,
});

const mapDispatchToProps = (dispatch: Dispatch): LoginDispatchProps => ({ dispatchSignIn: (login, password) => dispatch(signOn({ login, password })) });


const decorate = compose<React.FC>(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withTranslation('login'),
    reduxForm({
        form: LOGIN_FORM,
        initialValues: {
            login: '',
            password: '',
        },
        asyncValidate,
        shouldAsyncValidate
    })
);

export default decorate(LoginAuthorisation);
