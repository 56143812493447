import {
    AuthorisationStatus,
    SmsCode,
    StartAuthorisationResponse,
    VerifyCodeResponse
} from 'models/domain/authorisation';

import {
    CANCEL_AUTHORISATION,
    CANCEL_AUTHORISATION_FAILURE,
    CANCEL_AUTHORISATION_SUCCESS,
    CancelAuthorisation,
    CancelAuthorisationFailure,
    CancelAuthorisationSuccess,
    RESEND_CODE,
    RESEND_CODE_FAILURE,
    RESEND_CODE_SUCCESS,
    ResendCode,
    ResendCodeFailure,
    ResendCodeSuccess,
    START_AUTHORISATION,
    START_AUTHORISATION_FAILURE,
    START_AUTHORISATION_SUCCESS,
    StartAuthorisation,
    StartAuthorisationFailure,
    StartAuthorisationSuccess,
    VERIFY_CODE,
    VERIFY_CODE_FAILURE,
    VERIFY_CODE_SUCCESS,
    VerifyCode,
    VerifyCodeFailure,
    VerifyCodeSuccess
} from './authorisation.types';


export const startAuthorisation = (): StartAuthorisation => ({ type: START_AUTHORISATION });
export const startAuthorisationSuccess = (response: StartAuthorisationResponse): StartAuthorisationSuccess => ({ type: START_AUTHORISATION_SUCCESS, payload: response });
export const startAuthorisationFailure = (): StartAuthorisationFailure => ({ type: START_AUTHORISATION_FAILURE });

export const cancelAuthorisation = (): CancelAuthorisation => ({ type: CANCEL_AUTHORISATION });
export const cancelAuthorisationSuccess = (): CancelAuthorisationSuccess => ({ type: CANCEL_AUTHORISATION_SUCCESS });
export const cancelAuthorisationFailure = (): CancelAuthorisationFailure => ({ type: CANCEL_AUTHORISATION_FAILURE });

export const verifyCode = (code: SmsCode): VerifyCode => ({ type: VERIFY_CODE, payload: code });
export const verifyCodeSuccess = (response: VerifyCodeResponse): VerifyCodeSuccess => ({ type: VERIFY_CODE_SUCCESS, payload: response });
export const verifyCodeFailure = (): VerifyCodeFailure => ({ type: VERIFY_CODE_FAILURE });

export const resendCode = (): ResendCode => ({ type: RESEND_CODE });
export const resendCodeSuccess = (status: AuthorisationStatus): ResendCodeSuccess => ({ type: RESEND_CODE_SUCCESS, payload: status });
export const resendCodeFailure = (): ResendCodeFailure => ({ type: RESEND_CODE_FAILURE });
