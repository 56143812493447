import { Currency, Day, IBAN, ProfileId, UUID } from './common';


export type Xs2aAuthorisationId = UUID;

export enum ConsentType {
    ONE_OFF = 'ONE_OFF',
    RECURRING = 'RECURRING'
}

export enum ScopeType {
    DETAILS = 'DETAILS',
    BALANCES = 'BALANCES',
    TRANSACTIONS = 'TRANSACTIONS',
}

export interface AuthorisationDetails {
    profileId: ProfileId;
    profileDisplayName: string;
    tppName: string;
    tppRedirectUrl: string;
    consentDetails?: ConsentDetails;
    paymentDetails?: PaymentDetails;
}

export interface ConsentDetails {
    accounts: Account[];
    consentType: ConsentType;
    validUntil: Day;
    frequencyPerDay: number; // int (positive)
}

export interface Account {
    iban: IBAN;
    accountName: string; // length: 1-70
    accessScope: ScopeType[]; // length: 1-3
}

export interface PaymentDetails {
    fromAccountIban: IBAN;
    fromAccountName: string; // max. length: 70
    fromAccountCurrency: Currency;
    toAccountIban: IBAN;
    beneficiary: string; // max. length: 70
    amount: Amount;
    description: string;
}

export interface Amount {
    amount: number; // 16.2 (positive)
    currency: Currency;
}
