import React, { FunctionComponent } from 'react';

import Styled from 'components/reusable/Overalys/Overlays.styled';
import SpinnerPaletteRing from 'components/reusable/Spinners/SpinnerPaletteRing';


const SectionLoadingOverlay: FunctionComponent = () => (
    <Styled.SectionLoadingPlaceholder>
        <Styled.Center>
            <SpinnerPaletteRing />
        </Styled.Center>
    </Styled.SectionLoadingPlaceholder>
);

export default SectionLoadingOverlay;
