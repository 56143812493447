import styled, { css } from 'styled-components';
import { WithTheme } from 'config/theme';
import { IconFont } from 'assets/styles/icons/Icons.styled';
import { BgColorTransition } from 'assets/styles/animations/animations.styled';

const LoginPageWrapper = styled.main`
    background: rgb(255, 255, 255);
`;

const LoginStepWrap = styled.section<{ hasError?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 52px;
  height: 378px;
  padding: 23px 11px 38px;
  text-align: center;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, .16);
  background-color: ${({ theme }: WithTheme) => theme.colors.white};

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 8px;
    background-color: ${({ theme }: WithTheme) => theme.colors.accent.light};
    ${({ hasError, theme }) => hasError && css`background-color: ${theme.colors.error.base}`};
    ${BgColorTransition};
  }
`;

const PageHeader = styled.h1`
    margin: 0 0 55px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
    color: ${({ theme }: WithTheme) => theme.colors.black};
`;

export const PageSubHeader = styled.h2`
    margin: 0 0 28px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
    color: ${({ theme }: WithTheme) => theme.colors.font.onPrimaryLighter};
`;

export const SecondaryActionWrap = styled.section`
  padding: 23px 0 13px;
  line-height: 24px;
  text-align: center;
`;

export const InfoIcon = styled(IconFont)`
  position: absolute;
  top: 22px;
  right: 19px;
  font-size: 22px;
  cursor: help;
`;

export const DecorationIcon = styled(IconFont)`
  display: block;
  margin: 0 auto;
  font-size: 80px;
  pointer-events: none;
`;

export const DecorationImage = styled.img`
  display: block;
  margin: 0 auto;
`;

export default {
    LoginPageWrapper,
    LoginStepWrap,
    PageHeader,
    PageSubHeader,
    InfoIcon,
    SecondaryActionWrap,
    DecorationIcon,
    DecorationImage,
};
